import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace user {
  export function getUserInfo(): Promise<ApiBaseResult & { userData: ServerDataTypes.UserInfo }> {
    return get('/user/me')
  }
  export function logout(): Promise<ApiBaseResult> {
    return get('/user/logout')
  }

  // 记录用户访问行为
  export function postVisitorRecord(data: { token: string; actionType: string }) {
    return post('/visitor/record', {}, data)
  }

  export function postUninstallRecord(
    data: ServerDataTypes.UninstallRecordData
  ): Promise<{ recordId: string }> {
    return post('/uninstall_record', {}, data)
  }

  export function checkAlipaySignStatus() {
    return post('/alipay/agree_status')
  }

  export function alipayUnsign() {
    return post('/alipay/unsign_agree')
  }

  export function getEventRecordList(params: {
    offset: number
    limit: number
    //目前只有PDF
    eventType?: ServerDataTypes.EventType
  }): Promise<ApiBaseResult & { data: ServerDataTypes.EventRecordInfo[] }> {
    return get('/event_record/list', params)
  }

  export function getLanguageList(): Promise<
    ApiBaseResult & {
      language_list: {
        [key: string]: { name: string; nativeName: string; chineseName?: string }
      }
    }
  > {
    return post('/language/list', {}, {}, false)
  }
}
