import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect } from 'react'
import style from './PdfLimitModal.module.less'
import { Button, Modal } from 'antd'
import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { userService } from '@/services/userService'
import { MAX_PDF_PAGE_WITHOUT_PAY } from '@/pages/PdfPage/components/PdfViewer/PdfViewerVm'

/** PdfLimitModal 组件的 Props */
export type PdfLimitModalProps = {
  open: boolean
  limitType?: 'translate' | 'share' | 'download'
  onClose?: () => void
}

const PdfLimitModal: FC<PdfLimitModalProps> = observer(
  ({ open, limitType, onClose }: PdfLimitModalProps) => {
    useEffect(() => {
      if (open) {
        if (limitType) {
          userService.trackUserRecord(`limit_show_${limitType}`)
        } else {
          userService.trackUserRecord(`limit_show`)
        }
      }
    }, [open, limitType])

    const getContent = () => {
      switch (limitType) {
        case 'translate':
          return (
            <>
              <img
                className={classNames(style.cover)}
                src={commonUtils.getStaticFile('pdf/pdf-limit/translate-limit.png')}
              />
              <p className={classNames(style.bold)}>免费用户可以试看{MAX_PDF_PAGE_WITHOUT_PAY}页</p>
              <p className={classNames(style.text)}>
                升级会员可查看全部，
                <br />
                还可以下载和分享，快去试试吧～
              </p>
            </>
          )
        case 'share':
          return (
            <>
              <img
                className={classNames(style.cover)}
                src={commonUtils.getStaticFile('pdf/pdf-limit/share-limit.png')}
              />
              <p className={classNames(style.bold)}>仅会员用户支持分享</p>
              <p className={classNames(style.text)}>
                升级会员可享受无限次pdf翻译分享，快去试试吧～
              </p>
            </>
          )
        case 'download':
          return (
            <>
              <img
                className={classNames(style.cover)}
                src={commonUtils.getStaticFile('pdf/pdf-limit/download-limit.png')}
              />
              <p className={classNames(style.bold)}>仅会员用户支持下载</p>
              <p className={classNames(style.text)}>升级会员可享受无限次pdf下载，快去试试吧～</p>
            </>
          )
      }
    }
    return (
      <Modal
        zIndex={Z_INDEX_MAX}
        className={style.pdfLimitModal}
        centered
        closable
        width={364}
        open={open}
        footer={false}
        onCancel={onClose}
      >
        <div className={style.bg}></div>
        <div className={classNames(style.content)}>
          {getContent()}
          <Button
            className={classNames(style.btn)}
            onClick={() => {
              userService.doPay({
                wordPack: false,
                source: `free_limit_${limitType}`,
              })

              if (limitType) {
                userService.trackUserRecord(`limit_click_${limitType}`)
              } else {
                userService.trackUserRecord(`limit_click`)
              }
              onClose?.()
            }}
          >
            <span>去升级</span>
            <img
              className={classNames(style.icon)}
              src={commonUtils.getStaticFile('icon__right-arrow.png')}
            />
          </Button>
        </div>
      </Modal>
    )
  }
)

export default PdfLimitModal
