import { ComponentFactory } from '@/mobxTools'
import { Tooltip as AntTooltip } from 'antd'
import { TooltipPropsWithTitle } from 'antd/es/tooltip'
import classNames from 'classnames'
import { useEffect } from 'react'
import style from './Tooltip.module.less'
import { TooltipVm } from './TooltipVm'

/** Tooltip 组件的 Props */
export interface TooltipProps extends TooltipPropsWithTitle {
  title: string
  width?: number
  useParentContainer?: boolean
}

export const Tooltip = ComponentFactory.createComponent('Tooltip', TooltipVm, (vm) => {
  const { title, width, overlayClassName, useParentContainer } = vm.props
  useEffect(() => {
    if (vm.props.open !== undefined) {
      vm.open = vm.props.open
    }
  }, [vm.props.open, vm])
  // eslint-disable-next-line prefer-const
  let { children, ...otherProps } = vm.props
  const open = title ? vm.open : false
  return (
    <AntTooltip
      {...otherProps}
      open={open}
      overlayClassName={classNames(overlayClassName, style.tooltip)}
      onOpenChange={(value) => {
        vm.open = value
      }}
      overlayStyle={{
        width: width || 'max-content',
      }}
      // overlayInnerStyle={{
      //   whiteSpace: width ? 'normal' : 'nowrap',
      // }}
      getTooltipContainer={(el) => {
        const { getTooltipContainer } = vm.props
        if (getTooltipContainer) {
          return getTooltipContainer(el)
        }
        if (useParentContainer) {
          return el.parentElement!
        }
        return document.body
      }}
    >
      {children}
    </AntTooltip>
  )
})
