import { dayjsHelper } from '@/helpers/dayjsHelper'
import { ComponentFactory } from '@/mobxTools'
import { userService } from '@/services/userService'
import classNames from 'classnames'
import style from './VipExpiredTipsPanel.module.less'
import { VipExpiredTipsPanelVm } from './VipExpiredTipsPanelVm'

const PRO_BG =
  'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_bg__tips-pro.c589b34e792a.png'
const PRO_V_BG =
  'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_bg__tips-pro-v.cda2efc42da4.png'

const TIPS_CONTENT = [
  '1.当您购买多重会员套餐时，将会优先消耗【无限】会员有效天数，当【无限】会员有效天数消耗完毕后，再消耗 Pro 会员有效天数',
  '2.当【无限】会员到期后，剩余的 Pro 会员的各项权益仍按原 Pro 会员剩余量限制',
]
/** VipExpiredTipsPanel 组件的 Props */
export type VipExpiredTipsPanelProps = {
  from?: 'dialog'
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const VipExpiredTipsPanel = ComponentFactory.createComponent(
  'VipExpiredTipsPanel',
  VipExpiredTipsPanelVm,
  (vm) => {
    const userInfo = userService.userData
    if (userService.vipVersion === 'free') {
      return null
    }
    const {
      versionData: {
        version,
        endTime,
        vipTimeData: { proEndTime, proPlusEndTime, proStartTime },
      },
    } = userInfo!
    const vipInfo = {
      bg: version === 'pro' ? PRO_BG : PRO_V_BG,
      title: version === 'pro' ? '您当前是尊贵的【Pro会员】' : '您当前是尊贵的【无限会员】',

      expiredAtTime: dayjsHelper.formatTime(
        version === 'pro' ? endTime * 1000 : proPlusEndTime * 1000
      ),
      contents: TIPS_CONTENT,
    }
    const checkHasProExpiredText = () => {
      if (version !== 'pro+' || !proEndTime) {
        return false
      }
      if (proEndTime * 1000 <= Date.now()) {
        return false
      }
      return true
    }
    const proExpiredText = () => {
      if (!checkHasProExpiredText()) {
        return null
      }
      return (
        <p className={classNames(style.textPro)}>
          *「Pro会员」生效时间：
          {userService.alipayAgree
            ? `剩余${dayjsHelper.diffDay(proStartTime * 1000, proEndTime * 1000) + 1}天`
            : `${dayjsHelper.formatTime(proStartTime * 1000)}～
          ${dayjsHelper.formatTime(proEndTime * 1000)}`}
        </p>
      )
    }
    return (
      <div className={classNames(style.vipExpiredTipsPanel)}>
        {vm.props.from === 'dialog' ? (
          <img
            className={classNames(style.questionIcon)}
            src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__fill-global_question.3225d5513f8d.png'
          />
        ) : (
          <img
            className={classNames(style.questionIcon)}
            src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__fill-global_question.3225d5513f8d.png'
          />
        )}
        <div className={classNames(style.tipsBox)}>
          <p className={classNames(style.tipsTitle)}>会译会员</p>
          <div
            className={classNames(style.vipTips, { [style.noProText]: !checkHasProExpiredText() })}
          >
            <img src={vipInfo.bg} />
            <div className={classNames({ [style.proBox]: version === 'pro' })}>
              <p className={classNames(style.bold)}>{vipInfo.title}</p>
              <p className={classNames(style.text)}>
                {userService.alipayAgree ? '自动续费时间' : '过期时间'}:{vipInfo.expiredAtTime}
              </p>
            </div>
            {proExpiredText()}
          </div>
          <p className={classNames(style.tipsTitle)}>会译生效规则</p>
          <div className={classNames(style.tipsContent)}>
            {vipInfo.contents.map((c) => {
              return <p key={c}>{c}</p>
            })}
          </div>
        </div>
      </div>
    )
  }
)
