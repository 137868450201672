import { BaseVm } from '@/mobxTools'
import { observable } from 'mobx'
import { Timestamp, WelfareCountDownBoxProps } from '.'

export class WelfareCountDownBoxVm extends BaseVm<WelfareCountDownBoxProps> {
  props!: WelfareCountDownBoxProps

  @observable hourStr = ''
  @observable minuteStr = ''
  @observable secondStr = ''

  private timer?: any

  public onViewCreate() {
    const end = this.props.end
    if (!end) {
      return
    }
    this.countdown(end)
  }

  public onViewDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  private countdown(end: Timestamp) {
    const now = Date.now()

    if (end <= now) {
      this.hourStr = '00'
      this.minuteStr = '00'
      this.secondStr = '00'
      this.props.timeEnd()
      return
    }

    const processTime = (t: number) => {
      return t < 10 ? `0${t}` : t + ''
    }

    const counter = () => {
      let diff = Math.floor((end - Date.now()) / 1000)
      if (diff < 0) {
        diff = 0
      }
      const hours = Math.floor(diff / 3600)
      const minutes = Math.floor((diff / 60) % 60)
      const seconds = Math.floor(diff % 60)
      this.hourStr = processTime(hours)
      this.minuteStr = processTime(minutes)
      this.secondStr = processTime(seconds)
      this.timer = setTimeout(() => {
        if ([hours, minutes, seconds].every((num) => num === 0)) {
          clearTimeout(this.timer)
          this.props.timeEnd()
          return
        }
        counter()
      }, 1000)
    }

    counter()
  }
}
