import { toastController } from '@/controllers/toastController'
import { uiController } from '@/controllers/uiController'
import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import { routerService } from '@/services/routerService'
import { userService } from '@/services/userService'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import style from './PageHeader.module.less'
import { PAGE_LINKS } from '@/helpers/commonConsts'

interface PageHeaderProps {
  className?: string
  rightBoxVisible?: boolean
  isScrolling?: boolean
}

const PageHeader: FC<PageHeaderProps> = observer((props: PageHeaderProps) => {
  const { className, rightBoxVisible, isScrolling } = props
  const [showHelpBox, setShowHelpBox] = useState(false)

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setShowHelpBox(false)
    })
  }, [])

  const handleLogin = (type: 'login' | 'register', redirectUrl?: string) => {
    if (!commonUtils.isInstalledExtension()) {
      routerService.navigateTo('install', { from: 'login' })
      return
    }
    if (redirectUrl) {
      routerService.navigateTo('login', { from: 'home', type, redirectUrl })
    } else {
      routerService.navigateTo('login', { from: 'home', type })
    }
  }
  return (
    <div className={classNames(style.pageHeader, className, { [style.hasScroll]: isScrolling })}>
      <div className={classNames(style.leftBox)}>
        <a href='/' className={classNames('flex-row')}>
          {envHelper.oem === 'lenovo' ? (
            <img
              src={commonUtils.getStaticFile('cdn/home-page_icon__logo.png')}
              className={classNames(style.logo)}
            />
          ) : (
            <img
              src='https://assets.weibanzhushou.com/web/pt-web/home-page_icon__logo.1b9e042059a1.png'
              className={classNames(style.logo)}
            />
          )}
        </a>
      </div>
      <div
        className={classNames(style.rightBox, {
          [style.hide]: !rightBoxVisible || !userService.initialed,
        })}
      >
        <Link
          to={PAGE_LINKS.learning}
          className={style.linkBtn}
          onClick={() => {
            userService.recordLearningMode('click_header_learning')
          }}
        >
          学习模式
        </Link>
        <Link
          to='/education_incentives'
          className={style.linkBtn}
          onClick={(e) => {
            if (!userService.isLogin) {
              e.preventDefault()
              handleLogin('login', encodeURIComponent(window.location.origin + '/invite'))
            }
          }}
        >
          教育特惠
        </Link>
        <Link
          className={classNames(style.linkBtn)}
          to='/invite'
          onClick={(e) => {
            if (!userService.isLogin) {
              e.preventDefault()
              handleLogin('login', encodeURIComponent(window.location.origin + '/invite'))
            }
          }}
        >
          <span className={classNames('flex-row', 'justify-center')}>
            <img
              className={classNames(style.linkBtnIcon)}
              style={{ marginRight: '4px' }}
              src='https://assets.weibanzhushou.com/web/pt-web/invite-page_invite-gift.3cd481dca8f6.png'
            />
            <span>邀请有礼</span>
          </span>
        </Link>
        <Link to='https://huiyiai.net/blog/' target='_blank' className={style.linkBtn}>
          翻译指南
        </Link>
        <div
          className={classNames(style.linkBtn, style.linkBtnHelp)}
          onClick={(e) => {
            e.stopPropagation()
            setShowHelpBox(!showHelpBox)
          }}
        >
          联系我们
          <div className={classNames(style.helpBox, { [style.visible]: showHelpBox })}>
            <img
              className={classNames(style.qrCode)}
              src='https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E8%81%94%E7%B3%BB%E5%AE%A2%E6%9C%8D.126a016dd9f4.jpeg'
            />
          </div>
        </div>
        <Link className={classNames(style.linkBtn)} to='/pricing'>
          定价
        </Link>
        <div
          className={classNames(style.translationBtn, 'flex-row', 'justify-center', {
            [style.oemLenovo]: envHelper.oem === 'lenovo',
          })}
          onClick={() => {
            routerService.navigateTo('pdf')
          }}
        >
          {envHelper.oem === 'lenovo' ? (
            <img
              className={classNames(style.icon)}
              src={commonUtils.getStaticFile('cdn/translation-icon-v2.png')}
            />
          ) : (
            <img
              className={classNames(style.icon)}
              src='https://assets.weibanzhushou.com/web/pt-web/page-header_translation-icon-v2.03d44bfe83a8.png'
            />
          )}
          <p className={classNames(style.text)}>PDF翻译</p>
        </div>
        {!userService.isLogin && (
          <>
            <div
              className={classNames(style.btn)}
              onClick={() => {
                handleLogin('register')
              }}
            >
              免费注册
            </div>
            <div
              className={classNames(style.linkBtn)}
              onClick={() => {
                handleLogin('login')
              }}
            >
              登录
            </div>
          </>
        )}
        {userService.isLogin && <UserInfoBox></UserInfoBox>}
      </div>
    </div>
  )
})

const UserInfoBox = observer(() => {
  const [showJumpToSetting, setShowJumpToSetting] = useState(false)
  useEffect(() => {
    checkPDFAndExtensionOptionPageVisible()
    commonUtils.asyncDelay(500).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
    commonUtils.asyncDelay(2000).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
    commonUtils.asyncDelay(3000).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
  }, [])

  const checkPDFAndExtensionOptionPageVisible = () => {
    const show =
      commonUtils.isInstalledExtension() && !commonUtils.checkExtensionVersionShouldUpdate('1.1.0')
    setShowJumpToSetting(show)
  }
  const userInfo = userService.userData
  if (!userInfo) {
    return null
  }

  const { nickname, wxAvatar, inviteCode } = userInfo
  return (
    <div
      className={classNames(style.userInfoBox, {
        [style.oemLenovo]: envHelper.oem === 'lenovo',
      })}
    >
      {wxAvatar ? (
        <img className={classNames(style.avatar)} src={wxAvatar} />
      ) : (
        <img className={classNames(style.avatar)} src={commonUtils.getDefaultAvatar()} />
      )}
      <p className={classNames(style.nickname)}>
        {nickname ? nickname : `会译${inviteCode.slice(0, 4).toUpperCase()}`}
      </p>
      <img
        className={classNames(style.iconArrow)}
        src='https://assets.weibanzhushou.com/web/pt-web/page-header_triangle-icon.9d78d402ad4f.png'
      />
      <div className={classNames(style.menuBoxWrapper)}>
        <div className={classNames(style.menuBox)}>
          {showJumpToSetting && (
            <>
              <div
                className={classNames(style.menuLi)}
                onClick={() => {
                  commonUtils.sendExtensionMessage('openOptionPage', {})
                }}
              >
                <img
                  className={classNames(style.icon)}
                  src='https://assets.weibanzhushou.com/web/pt-web/page-header_setup-center.6e0079fe237e.png'
                />
                <span className={classNames(style.text)}>设置中心</span>
              </div>
              <div className={classNames(style.line)}></div>
            </>
          )}
          <div
            className={classNames(style.menuLi)}
            onClick={() => {
              uiController.exchangeDialogProps = { open: true }
            }}
          >
            <img
              className={classNames(style.icon)}
              src='https://assets.weibanzhushou.com/web/pt-web/page-header_icon__coupon.578fecf1565b.png'
            />
            <span className={classNames(style.text)}>兑换码</span>
          </div>
          <div
            className={classNames(style.menuLi)}
            onClick={async () => {
              await userService.logout()
              toastController.success('退出成功')
              await commonUtils.asyncDelay(1000)
              routerService.navigateTo('home')
            }}
          >
            <img
              className={classNames(style.icon)}
              src='https://assets.weibanzhushou.com/web/pt-web/page-header_icon__logout.0d3a4d0087e0.png'
            />
            <span className={classNames(style.text)}>退出登录</span>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PageHeader
