import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { BaseVm } from '@/mobxTools'
import { PRO_BENEFITS, PRO_V_BENEFITS } from '@/pages/PricingPage/pricingConsts'
import { buyVipService } from '@/services/buyVipService'
import { userService } from '@/services/userService'
import { computed, observable } from 'mobx'
import { BuyVipDialogV1Props } from '.'
import { uiController } from '@/controllers/uiController'

export const SUBSCRIPTION_TYPE = ['year_subscription', 'quarter_subscription', 'month_subscription']
export interface Plan extends ServerDataTypes.VipPlan {
  id: string
  title?: string
  tokenCount: number
  recommend?: boolean
  priceStr: number
  originalPriceStr: string
  isSubscription?: boolean
  priceDesc?: string
}
export class BuyVipDialogV1Vm extends BaseVm<BuyVipDialogV1Props> {
  props!: BuyVipDialogV1Props

  @observable selectPlan!: Plan
  @observable hasDiscountPlan = false
  @observable currentTabKey: 'wx_pub_qr' | 'alipay_qr' = 'alipay_qr'
  @observable orderCreating = true
  @observable orderQrCode = ''
  @observable qrErr = false
  @observable discountEndTime!: number
  @observable funcList: ServerDataTypes.BuyVipDialogExtraData['featureList'] = []
  @observable curFuncKey = 'web'
  @observable vipGiftList: ServerDataTypes.BuyVipDialogExtraData['vipGiftList'] = []
  @observable curVipType: 'pro' | 'pro+' = 'pro'
  @observable alipayAgree = false
  private proBenefits: ServerDataTypes.BenefitsInfo = PRO_BENEFITS
  private proVBenefits: ServerDataTypes.BenefitsInfo = PRO_V_BENEFITS
  @computed get benefits() {
    if (this.curVipType === 'pro+') {
      return this.proVBenefits
    }
    return this.proBenefits
  }
  @computed get vipPlans() {
    if (this.curVipType === 'pro+') {
      return commonUtils.jsonClone(this.proPlusVipPlans)
    }
    return commonUtils.jsonClone(this.proVipPlans)
  }
  private sessionId?: string
  private proVipPlans: Plan[] = []
  private proPlusVipPlans: Plan[] = []
  private timer?: any

  public async onViewCreate() {
    await this.checkAlipaySignStatus()
    this.initPlansData()
    this.initExtraData()
    try {
      const {
        versionData: { alipayAgree },
      } = userService.userData!
      userService.trackUserRecord('buyVipDialogV1_vip')
      this.alipayAgree = alipayAgree
    } catch (error) {}
  }
  public onViewDestroy(): void {
    // view 被销毁后执行
    this.clearTimeout()
    this.sessionId = undefined
  }

  public transferToStr = (count: number) => {
    return count.toLocaleString()
  }

  public transferTokenCount = (cnt: number) => {
    return `${Math.floor(cnt / 10000)}W`
  }

  public handleChangeVipType(type: 'pro' | 'pro+') {
    if (this.curVipType !== type) {
      this.curVipType = type
      this.selectPlan = type === 'pro' ? this.proVipPlans[0] : this.proPlusVipPlans[0]
      this.genOrderQrCode()
    }
  }

  public async genOrderQrCode() {
    if (!userService.isLogin) {
      console.error('未找到用户登录态')
      return
    }
    if (this.alipayAgree && this.selectPlan.isSubscription) {
      this.qrErr = true
      this.orderCreating = false
      this.orderQrCode =
        this.orderQrCode ||
        'https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E8%81%94%E7%B3%BB%E5%AE%A2%E6%9C%8D.126a016dd9f4.jpeg'
      return
    }
    this.orderCreating = true
    this.qrErr = false
    const sessionId = commonUtils.genId()
    this.sessionId = sessionId
    try {
      const { qr, orderId } = await buyVipService.createOrder({
        action: userService.userData?.versionData.version === 'free' ? 'buy' : 'renew',
        chargeChannel: this.selectPlan.isSubscription ? 'alipay_agreement_qr' : this.currentTabKey,
        planId: this.selectPlan.id,
        version: this.selectPlan.version,
        source: `${this.props.source!}`,
      })
      if (this.sessionId !== sessionId) {
        return
      }
      this.orderQrCode = qr
      /** 取消上一次轮询 */
      this.clearTimeout()
      this.pollingOrderStatus(orderId, true)
    } catch (error) {
      console.log(error)
      this.qrErr = true
    }
    this.orderCreating = false
  }

  handlePriceChange(): void {
    if (this.selectPlan.isSubscription) {
      this.currentTabKey = 'alipay_qr'
    }
    this.genOrderQrCode()
  }

  private async initExtraData() {
    const { vipGiftList, featureList, proBenefits, proVBenefits } =
      await buyVipService.getBuyVipDialogExtraData()
    this.funcList = featureList
    this.curFuncKey = featureList[0].key
    this.vipGiftList = vipGiftList
    this.proBenefits = proBenefits
    this.proVBenefits = proVBenefits
  }

  private formatPlan(plan: ServerDataTypes.VipPlan) {
    return {
      ...plan,
      title: this.transTitle(plan.count, plan.timeType),
      priceStr: Math.floor(plan.price / 100),
      recommend: plan.timeType === 'year',
      per:
        plan.timeType === 'year'
          ? Math.floor(plan.price / (12 * plan.count * 100))
          : Math.floor(plan.price / (plan.count * 100)),
      originalPriceStr: Math.floor(plan.originPrice / 100).toString(),
      isSubscription: SUBSCRIPTION_TYPE.includes(plan.timeType),
      priceDesc: this.formatPriceDesc(plan),
    }
  }

  private async initPlansData() {
    try {
      const { planList, matchType, proPlusPlanList, discountPlan } = await buyVipService.list()
      this.proVipPlans = planList.map((plan) => {
        return this.formatPlan(plan)
      })
      this.proPlusVipPlans = proPlusPlanList.map((plan) => {
        return this.formatPlan(plan)
      })
      if (this.props.version === 'pro+') {
        this.curVipType = 'pro+'
        const findPlan = this.proPlusVipPlans.find((v) => {
          if (this.props.selectedPlanId) {
            return v.id === this.props.selectedPlanId
          }
          return v.newUserDiscount
        })
        this.selectPlan = findPlan || this.proPlusVipPlans[0]
      } else {
        this.curVipType = 'pro'
        const findPlan = this.proVipPlans.find((v) => {
          if (this.props.selectedPlanId) {
            return v.id === this.props.selectedPlanId
          }
          return v.newUserDiscount
        })
        this.selectPlan = findPlan || this.proVipPlans[0]
      }

      //不根据props.version决定，如果有新人福利套餐，优先展示新人福利套餐的tab
      if (discountPlan) {
        this.curVipType = discountPlan.version
        this.selectPlan = this.formatPlan(discountPlan)
        this.hasDiscountPlan = true
      }

      this.handlePriceChange()
      this.initDiscountInfo(matchType)
    } catch (error) {
      console.error('获取套餐列表失败')
    }
  }

  private transTitle(count: number, type: ServerDataTypes.VipTimeType) {
    switch (type) {
      case 'year':
        return `${count * 12}个月`
      case 'month_subscription':
        return '连续包月'
      case 'quarter_subscription':
        return '连续包季'
      case 'year_subscription':
        return '连续包年'
      default:
        return `${count}个月`
    }
  }

  private formatPriceDesc(plan: ServerDataTypes.VipPlan) {
    const { timeType, originPrice } = plan
    const originPriceStr = Math.floor(originPrice / 100)
    switch (timeType) {
      case 'month_subscription':
        return `首月到期后 ${originPriceStr}元/月`
      case 'quarter_subscription':
        return `首季到期后 ${originPriceStr}元/季`
      case 'year_subscription':
        return `首年到期后 ${originPriceStr}元/年`
      default:
        return ''
    }
  }

  private async pollingOrderStatus(orderId: string, force = false, cnt = 0) {
    if (!this.props.open) {
      return
    }
    if (force) {
      try {
        const { status } = await buyVipService.pollOrderStatus(orderId)
        if (!this.sessionId) {
          return
        }
        if (status === 'completed') {
          await userService.updateUserInfo()
          if (this.props.paySuccess) {
            this.props.paySuccess(this.selectPlan.timeType === 'year')
          }
          setTimeout(() => {
            if (this.props.onClose) {
              this.props.onClose(true)
            }
          })
          /** 停止轮询,给出成功提示 */
          this.clearTimeout()
          try {
            commonUtils.sendExtensionMessage('markPaid', {})
          } catch (error) {}
          return
        }
        if (status === 'canceled' || status === 'refunded') {
          /** 停止轮询 */
          return
        }
        if (status === 'created') {
          this.timer = setTimeout(() => {
            this.pollingOrderStatus(orderId, true, 0)
          }, 1000)
        }
      } catch (error) {
        if (cnt > 5) {
          this.clearTimeout()
          this.qrErr = true
          /** 重试结束，停止轮询 */
          return
        }
        this.pollingOrderStatus(orderId, true, cnt + 1)
      }
    }
  }

  private clearTimeout() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  private initDiscountInfo(matchType: ServerDataTypes.WelfareMatchType) {
    if (!userService.isLogin) {
      return
    }
    const {
      newUserDiscount,
      createdAt,
      versionData: { endTime },
    } = userService.userData!
    if (newUserDiscount) {
      this.discountEndTime = createdAt * 1000 + 48 * 60 * 60 * 1000
    } else if (matchType === 'exchange_code') {
      this.discountEndTime = endTime * 1000
    }
  }

  private async checkAlipaySignStatus() {
    try {
      await api.user.checkAlipaySignStatus()
      userService.updateUserInfo()
    } catch (error) {
      //
    }
  }

  public async alipayUnsign() {
    await api.user.alipayUnsign()
    userService.updateUserInfo()
  }
}
