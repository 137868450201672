import { BaseVm } from '@/mobxTools'
import { observable } from 'mobx'
import type { TooltipProps } from './Tooltip'

export class TooltipVm extends BaseVm<TooltipProps> {
  /** props 不应该被修饰为 state */
  props!: TooltipProps

  // states
  @observable open = false

  public onViewCreate(): void {
    // view 被创建完成后执行
  }

  public onViewDestroy(): void {
    // view 被销毁后执行
  }
}
