import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import style from './NewUserDiscountModal.module.less'
import { Modal } from 'antd'
import { uiController } from '@/controllers/uiController'
import { NewUserDiscount } from '../NewUserDiscount/NewUserDiscount'

/** NewUserDiscountModal 组件的 Props */
export type NewUserDiscountModalProps = {}

const NewUserDiscountModal: FC<NewUserDiscountModalProps> = observer(
  (props: NewUserDiscountModalProps) => {
    const [scaleNum, setScaleNum] = useState(1)
    const calcScaleNum = useCallback(() => {
      const fn = () => {
        const h = window.innerHeight
        if (h > 770) {
          return 1
        }
        return h / (770 + 20)
      }
      setScaleNum(fn())
    }, [])
    useEffect(() => {
      calcScaleNum()
      window.addEventListener('resize', calcScaleNum.bind(this))
      return window.removeEventListener('resize', calcScaleNum.bind(this))
    }, [])
    if (!uiController.showDiscountModal || !uiController.discount) {
      return null
    }
    return (
      <Modal
        open={uiController.showDiscountModal}
        width={1104}
        footer={null}
        title={null}
        centered={true}
        maskClosable={false}
        transitionName=''
        wrapClassName={classNames(style.newUserDiscountModal, {
          [style.proVModal]: uiController.discount.version === 'pro+',
        })}
        styles={{
          content: {
            borderRadius: '28px',
            padding: '38px 64px 16px',
            transform: `scale(${scaleNum})`,
          },
        }}
        onCancel={() => {
          uiController.showDiscountModal = false
          // vm.openPdf()
          uiController.closeLoginModal()
        }}
      >
        <div className={classNames(style.newUserDiscountBg)}></div>
        <NewUserDiscount
          styleCSS={{ paddingTop: '0px' }}
          discountEndTime={uiController.discountEndTime!}
          discount={uiController.discount}
          onSkipClick={() => {
            uiController.showDiscountModal = false
            // vm.openPdf()
            uiController.closeLoginModal()
          }}
          onJoinClick={async () => {
            uiController.showPayQr = true
          }}
        />
      </Modal>
    )
  }
)

export default NewUserDiscountModal
