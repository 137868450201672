import { ComponentFactory } from '@/mobxTools'
import classNames from 'classnames'
import style from './WelfareCountDownBox.module.less'
import { WelfareCountDownBoxVm } from './WelfareCountDownBoxVm'

/** 时间戳，单位毫秒 */
export type Timestamp = number
/** WelfareCountDownBox 组件的 Props */
export type WelfareCountDownBoxProps = {
  className?: string
  prefixText?: string
  end: Timestamp
  timeEnd: () => void
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const WelfareCountDownBox = ComponentFactory.createComponent(
  'WelfareCountDownBox',
  WelfareCountDownBoxVm,
  (vm) => {
    const { className, prefixText } = vm.props
    return (
      <section className={classNames(style.welfareCountDownBox, className, 'hy-countdown-box')}>
        <span className={classNames(style.prefix, 'hy-prefix')}>
          {prefixText || '福利失效倒计时'}：
        </span>
        <span className={classNames(style.count, 'hy-count')}>{vm.hourStr || '00'}</span>
        <span className={classNames(style.unit, 'hy-unit')}>时</span>
        <span className={classNames(style.count, 'hy-count')}>{vm.minuteStr || '00'}</span>
        <span className={classNames(style.unit, 'hy-unit')}>分</span>
        <span className={classNames(style.count, 'hy-count')}>{vm.secondStr || '00'}</span>
        <span className={classNames(style.unit, 'hy-unit')}>秒</span>
      </section>
    )
  }
)
