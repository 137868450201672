import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { ComponentFactory } from '@/mobxTools'
import { Modal, Spin } from 'antd'
import classNames from 'classnames'
import style from './PaySuccessDialog.module.less'
import { PaySuccessDialogVm } from './PaySuccessDialogVm'

/** PaySuccessDialog 组件的 Props */
export type PaySuccessDialogProps = {
  open: boolean
  enableGiftReceiveBtn?: boolean
  btnText?: string
  onClose?: () => void
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const PaySuccessDialog = ComponentFactory.createComponent(
  'PaySuccessDialog',
  PaySuccessDialogVm,
  (vm) => {
    const { enableGiftReceiveBtn, open, btnText, onClose } = vm.props
    if (!open) {
      return null
    }
    return (
      <Modal
        open={open}
        zIndex={Z_INDEX_MAX}
        maskClosable={false}
        centered
        width={enableGiftReceiveBtn ? 516 : 332}
        footer={false}
        getContainer={false}
        wrapClassName={style.paySuccessDialog}
        onCancel={() => {
          if (onClose) {
            onClose()
          }
        }}
      >
        <div className={classNames(style.paySuccessDialogContent)}>
          <p className={classNames(style.title)}>支付成功</p>
          <img
            className={classNames(style.successIcon)}
            src='https://assets.weibanzhushou.com/web/pt-web/pricing-page_success.a091509e2a4b.png'
          />
          <div className={classNames(style.btnBox, style.flexRow)}>
            {enableGiftReceiveBtn && (
              <div className={classNames(style.giftReceiveBtn)}>
                领取会员礼包
                <div className={classNames(style.giftQrBox)}>
                  <p className={classNames(style.qrT)}>微信扫码领取</p>
                  <div className={classNames(style.qrWithBg)}>
                    <img
                      src='https://assets.weibanzhushou.com/web/pt-web/login-page_bg__qr.518234b9d754.png'
                      className={classNames(style.qrBg)}
                    />
                    <Spin spinning={!vm.qrCode} size='small'>
                      <img className={classNames(style.qrCode)} src={vm.qrCode} />
                    </Spin>
                  </div>
                </div>
              </div>
            )}
            <div
              className={classNames(style.btn)}
              onClick={() => {
                if (onClose) {
                  onClose()
                }
              }}
            >
              {btnText || '关闭'}
            </div>
          </div>
          <p className={classNames(style.desc)}>
            <img
              className={classNames(style.icon)}
              src='https://assets.weibanzhushou.com/web/pt-web/login-page_icon__select-gray.0478c24d27c8.png'
            />
            可联系客服开发票
          </p>
        </div>
      </Modal>
    )
  }
)
