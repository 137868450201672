import { get, post } from './common'
// type ApiBaseResult<T> = Promise<ApiTypes.ApiBaseResult & T>

export namespace pricing {
  export function list(newUser?: 1) {
    return get<{
      planList: ServerDataTypes.VipPlan[]
      proPlusPlanList: ServerDataTypes.VipPlan[]
      tokenPlanList: ServerDataTypes.VipPlan[]
      eduPlanList: ServerDataTypes.VipPlan[]
    }>('/vip/plan', { newUser })
  }

  export function createOrder(p: PostDataTypes.OrderCreateParams) {
    return post<{
      chargeChannel: 'wx_pub_qr'
      orderId: string
      qr: string
      paymentData: {
        appId: string
        nonceStr: string
        package: string
        paySign: string
        signType: string
        timeStamp: number
      }
    }>('/vip/order/create', {}, p)
  }

  export function pollOrderStatus(orderId: string) {
    return get<{
      /** created: 待支付     completed: 已支付     canceled: 已取消     refunded: 已退款 */
      status: 'created' | 'completed' | 'canceled' | 'refunded'
    }>('/vip/order/status', { orderId })
  }

  // 使用兑换码
  export function userExchangeCode(code: string) {
    return post<{
      code: string
      successMsg: string
    }>('/use/exchange_code', {}, { code })
  }

  export function getWelfarePlan(newUser?: 1) {
    return get<ServerDataTypes.WelfarePlanData>('/user/favorable_plan', { newUser })
  }

  // 获取付费弹窗上需要的信息
  export function getBuyVipDialogExtraData(configInfo?: string) {
    return post<{ data: ServerDataTypes.BuyVipDialogExtraData }>(
      '/buy_vip_dialog_extra_data',
      {},
      { configInfo }
    )
  }

  export function getVipGiftReceiveQr() {
    return get<{ url: string; ticket: string }>('/user/paid/gift_qr')
  }
}
