import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { ComponentFactory } from '@/mobxTools'
import { Modal, QRCode, Spin } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { Plan } from '../BuyVipDialogV1/BuyVipDialogV1Vm'
import style from './BuyTokenPackageDialog.module.less'
import { BuyTokenPackageDialogVm } from './BuyTokenPackageDialogVm'

/** BuyTokenPackageDialog 组件的 Props */
export type BuyTokenPackageDialogProps = {
  open: boolean
  source?: string
  onClose?: (isPaySuccessful: boolean) => void
  paySuccess?: () => void
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const BuyTokenPackageDialog = ComponentFactory.createComponent(
  'BuyTokenPackageDialog',
  BuyTokenPackageDialogVm,
  (vm) => {
    const { open, onClose } = vm.props
    if (!vm.selectPlan) {
      return null
    }
    return (
      <Modal
        wrapClassName={classNames(style.buyTokenPackageDialog)}
        zIndex={Z_INDEX_MAX}
        maskClosable={false}
        open={open}
        centered
        footer={false}
        width='572px'
        getContainer={false}
        onCancel={() => {
          if (onClose) {
            onClose(false)
          }
        }}
      >
        <div className={classNames(style.buyTokenPackageContent)}>
          <p className={classNames(style.title)}>购买加量包</p>
          <div className={classNames(style.wordPackTipsBox)}>
            <img
              src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_icon__bell-new.210bc4422b89.png'
              className={classNames(style.bellIcon)}
            />
            <span>
              会员每月Token不够用时，可购买加量包（加量包可结转，每月优先消耗套餐内Token）
            </span>
          </div>
          <ul className={classNames(style.wordPackPlans)}>
            {vm.wordPackPlans.map((plan) => {
              return <PlanItem key={plan.id} vm={vm} plan={plan}></PlanItem>
            })}
          </ul>
          <div className={classNames(style.paymentBox)}>
            <div className={classNames(style.qrBox)}>
              <img
                src='https://assets.weibanzhushou.com/web/pt-web/login-page_bg__qr.518234b9d754.png'
                className={classNames(style.qrBg)}
              />
              <Spin spinning={vm.orderCreating} size='small'>
                <QRCode
                  status={vm.orderCreating ? 'loading' : 'active'}
                  value={vm.orderQrCode}
                  size={174}
                  bordered={false}
                  style={{ padding: 0 }}
                ></QRCode>
              </Spin>
              {vm.qrErr && (
                <div
                  className={classNames(style.reloadBox)}
                  onClick={() => {
                    vm.genOrderQrCode()
                  }}
                >
                  <img src='https://assets.weibanzhushou.com/web/pt-web/icon__qr-reload.b68e5cd3c901.png' />
                  <span>刷新</span>
                </div>
              )}
            </div>
            <div className={classNames(style.right)}>
              <div className={classNames(style.channelList)}>
                <div
                  className={classNames(style.alipayBox, style.flexRow, {
                    [style.active]: vm.currentTabKey === 'alipay_qr',
                  })}
                  onClick={() => {
                    vm.currentTabKey = 'alipay_qr'
                    vm.genOrderQrCode()
                  }}
                >
                  <img src='https://assets.weibanzhushou.com/web/pt-web/ali-pay.18b4779d8424.png' />
                  <span>支付宝支付</span>
                </div>
                <div
                  className={classNames(style.wechatBox, style.flexRow, {
                    [style.active]: vm.currentTabKey === 'wx_pub_qr',
                  })}
                  onClick={() => {
                    vm.currentTabKey = 'wx_pub_qr'
                    vm.genOrderQrCode()
                  }}
                >
                  <img src='https://assets.weibanzhushou.com/web/pt-web/wechat-pay.9115a8ba339f.png' />
                  <span>微信支付</span>
                </div>
              </div>
              <p className={classNames(style.vipTitleBold)}>
                加量包-{vm.selectPlan.tokenCount.toLocaleString()} Token
              </p>
              <p className={classNames(style.vipTotal)}>￥{vm.selectPlan.priceStr}</p>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
)

const PlanItem = observer(({ vm, plan }: { vm: BuyTokenPackageDialogVm; plan: Plan }) => {
  return (
    <li
      key={plan.id}
      className={classNames({ [style.active]: plan.id === vm.selectPlan.id })}
      onClick={() => {
        vm.selectPlan = plan
        vm.handlePriceChange()
      }}
    >
      <img
        src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_bg__token-package.bd7249ce6409.png'
        alt=''
        className={classNames(style.planActiveBg)}
      />
      <img
        src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__word-count-active.e4b46358d388.png'
        className={classNames(style.iconPlanActive)}
      />
      <div className={classNames(style.selectionBoxMiddle)}>
        <span className={classNames(style.pricePrefix)}>￥</span>
        <span className={classNames(style.priceText)}>{plan.priceStr}</span>
      </div>
      <div className={classNames(style.tokenCount)}>{plan.tokenCount.toLocaleString()} Token</div>
    </li>
  )
})
