import { BaseVm } from '@/mobxTools'
import { buyVipService } from '@/services/buyVipService'
import { observable } from 'mobx'
import { PaySuccessDialogProps } from '.'

export class PaySuccessDialogVm extends BaseVm<PaySuccessDialogProps> {
  props!: PaySuccessDialogProps

  @observable qrCode = ''

  public onViewCreate() {}

  public onPropsChange() {
    if (!this.props.open) {
      return
    }
    if (this.props.enableGiftReceiveBtn && !this.qrCode) {
      this.initGiftReceiveQr()
    }
  }

  private async initGiftReceiveQr() {
    const { url } = await buyVipService.getVipGiftReceiveQr()
    this.qrCode = url
  }
}
