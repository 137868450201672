import { ElementType, Suspense, lazy, useEffect, useState } from 'react'
import { RouteObject, useRoutes, Navigate } from 'react-router-dom'
import AmeSpin from './components/AmeSpin/AmeSpin'
import { PAGE_LINKS } from './helpers/commonConsts'
import { OnlineTranslation } from './pages/OnlineTranslationPage/OnlineTranslation'
import { observer } from 'mobx-react'
import { userService } from './services/userService'
import { routerService } from './services/routerService'

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<AmeSpin fullscreen={true} />}>
      <Component {...props} />
    </Suspense>
  )

const AuthRoute = observer(({ children }: any) => {
  const [canRender, setRenderFlag] = useState(false)

  useEffect(() => {
    async function checkLogin() {
      if (!userService.isLogin) {
        await userService.updateUserInfo()
        if (!userService.userData) {
          routerService.navigateTo('login', {
            redirectUrl: encodeURIComponent(window.location.href),
          })
          return
        }
      }
      setRenderFlag(true)
    }

    checkLogin()
  }, [userService.isLogin])
  if (!canRender) return null
  return children
})

const PdfPage = Loadable(lazy(() => import('./pages/PdfPage/PdfPage')))
const HomePage = Loadable(lazy(() => import('./pages/HomePageV1/HomePageV1')))
const UninstallPage = Loadable(lazy(() => import('./pages/UninstallPage/UninstallPage')))
const WelcomePage = Loadable(lazy(() => import('./pages/WelcomePage/WelcomePage')))
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage/LoginPage')))
const PricingPage = Loadable(lazy(() => import('./pages/PricingPage/PricingPage')))
const H5Invite = Loadable(lazy(() => import('./pages/H5InvitePage/H5InvitePage')))
const H5Price = Loadable(lazy(() => import('./pages/H5PricePage/H5PricePage')))
const H5Exchange = Loadable(lazy(() => import('./pages/H5ExchangePage/H5ExchangePage')))
const InstallPage = Loadable(lazy(() => import('./pages/InstallPage/InstallPage')))
const InvitePage = Loadable(lazy(() => import('./pages/InvitePage/InvitePage')))
const SharePage = Loadable(lazy(() => import('./pages/ShareWxMpPage/ShareWxMpPage')))
const EducationIncentivesPage = Loadable(
  lazy(() => import('./pages/EducationIncentivesPage/EducationIncentivesPage'))
)
const LearningModelPage = Loadable(
  lazy(() => import('./pages/LearningModelPage/LearningModelPage'))
)

const routes: RouteObject[] = [
  {
    path: PAGE_LINKS.home,
    element: <HomePage />,
  },
  {
    path: PAGE_LINKS.login,
    element: <LoginPage />,
  },
  {
    path: PAGE_LINKS.uninstall,
    element: <UninstallPage />,
  },
  {
    path: PAGE_LINKS.welcome,
    element: <WelcomePage />,
  },
  {
    path: PAGE_LINKS.pdf,
    element: <PdfPage />,
  },
  {
    path: PAGE_LINKS.oldPdf,
    element: <PdfPage remainOldTranslation />,
  },
  {
    path: PAGE_LINKS.pricing,
    element: <PricingPage />,
  },
  {
    path: PAGE_LINKS.h5_invite,
    element: <H5Invite />,
  },
  {
    path: PAGE_LINKS.h5_price,
    element: <H5Price />,
  },
  {
    path: PAGE_LINKS.h5_exchange,
    element: <H5Exchange />,
  },
  {
    path: PAGE_LINKS.install,
    element: <InstallPage />,
  },
  {
    path: PAGE_LINKS.invite,
    element: <InvitePage />,
  },
  {
    path: PAGE_LINKS.share_wx_mp,
    element: <SharePage />,
  },
  {
    path: PAGE_LINKS.help,
    element: <Navigate to={PAGE_LINKS.home} />,
  },
  {
    path: PAGE_LINKS.education_incentives,
    element: <EducationIncentivesPage />,
  },
  {
    path: PAGE_LINKS.onlineTranslation,
    element: <OnlineTranslation />,
  },
  {
    path: PAGE_LINKS.learning,
    element: (
      <AuthRoute>
        <LearningModelPage />
      </AuthRoute>
    ),
  },
]

export const Router = () => {
  return useRoutes(routes)
}
