import { commonUtils } from '@/helpers/commonUtils'
import { BaseVm } from '@/mobxTools'
import { buyVipService } from '@/services/buyVipService'
import { userService } from '@/services/userService'
import { observable } from 'mobx'
import { BuyTokenPackageDialogProps } from '.'
import { Plan } from '../BuyVipDialogV1/BuyVipDialogV1Vm'

export class BuyTokenPackageDialogVm extends BaseVm<BuyTokenPackageDialogProps> {
  props!: BuyTokenPackageDialogProps
  @observable selectPlan!: Plan
  @observable wordPackPlans: Plan[] = []

  @observable currentTabKey: 'wx_pub_qr' | 'alipay_qr' = 'alipay_qr'
  @observable orderCreating = true
  @observable orderQrCode = ''
  @observable qrErr = false

  private sessionId?: string
  private timer?: NodeJS.Timeout

  public onViewCreate(): void {
    this.initPlansData()
    userService.trackUserRecord('buyVipDialogV1_token')
  }
  public onViewDestroy(): void {
    // view 被销毁后执行
    this.clearTimeout()
  }

  public transferTokenCount = (cnt: number) => {
    return `${Math.floor(cnt / 10000)}W`
  }

  public async genOrderQrCode() {
    if (!userService.isLogin) {
      console.error('未找到用户登录态')
      return
    }
    this.orderCreating = true
    this.qrErr = false
    const sessionId = commonUtils.genId()
    this.sessionId = sessionId
    try {
      const { qr, orderId } = await buyVipService.createOrder({
        action: userService.userData?.versionData.version === 'free' ? 'buy' : 'renew',
        chargeChannel: this.currentTabKey,
        planId: this.selectPlan.id,
        version: 'pro',
        source: `${this.props.source!}`,
      })
      if (this.sessionId !== sessionId) {
        return
      }
      this.orderQrCode = qr
      /** 取消上一次轮询 */
      this.clearTimeout()
      this.pollingOrderStatus(orderId, true)
    } catch (error) {
      console.log(error)
      this.qrErr = true
    }
    this.orderCreating = false
  }

  handlePriceChange(): void {
    this.genOrderQrCode()
  }

  private async initPlansData() {
    try {
      const { tokenPlanList } = await buyVipService.list()

      this.wordPackPlans = tokenPlanList.map((plan) => {
        return {
          ...plan,
          priceStr: Math.floor(plan.price / 100),
          originalPriceStr: Math.floor(plan.originPrice / 100).toString(),
        }
      })
      this.selectPlan = this.wordPackPlans[0]
      this.genOrderQrCode()
    } catch (error) {
      console.error('获取套餐列表失败')
    }
  }
  private async pollingOrderStatus(orderId: string, force = false, cnt = 0) {
    if (!this.props.open) {
      return
    }
    if (force) {
      try {
        const { status } = await buyVipService.pollOrderStatus(orderId)
        if (status === 'completed') {
          try {
            commonUtils.sendExtensionMessage('markPaid', {})
          } catch (error) { }
          if (this.props.paySuccess) {
            this.props.paySuccess()
          }
          setTimeout(() => {
            if (this.props.onClose) {
              this.props.onClose(true)
            }
          })
          /** 停止轮询,给出成功提示 */
          this.clearTimeout()
          return
        }
        if (status === 'canceled' || status === 'refunded') {
          /** 停止轮询 */
          return
        }
        if (status === 'created') {
          this.timer = setTimeout(() => {
            this.pollingOrderStatus(orderId, true, 0)
          }, 1000)
        }
      } catch (error) {
        if (cnt > 5) {
          this.clearTimeout()
          this.qrErr = true
          /** 重试结束，停止轮询 */
          return
        }
        this.pollingOrderStatus(orderId, true, cnt + 1)
      }
    }
  }

  private clearTimeout() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
