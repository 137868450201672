import { commonUtils } from '@/helpers/commonUtils'
import { BaseVm } from '@/mobxTools'
import { buyVipService } from '@/services/buyVipService'
import { userService } from '@/services/userService'
import { observable } from 'mobx'
import { PayQrDialogProps } from '.'

export class PayQrDialogVm extends BaseVm<PayQrDialogProps> {
  props!: PayQrDialogProps

  @observable visible = false
  @observable currentTabKey: 'wx_pub_qr' | 'alipay_qr' = 'alipay_qr'
  @observable qrUrl?: string
  @observable qrErr = false
  private timer?: any
  private sessionId?: string
  public onViewDestroy() {
    this.clearTimeout()
  }

  public onPropsChange() {
    if (!this.props.open || !this.props.plan) {
      this.clearTimeout()
      return
    }
    this.genOrderQrCode()
  }

  public async genOrderQrCode() {
    if (!userService.isLogin) {
      console.error('未找到用户登录态')
      return
    }
    this.qrErr = false
    this.qrUrl = ''
    const sessionId = commonUtils.genId()
    this.sessionId = sessionId
    const selectPlan = this.props.plan
    try {
      const { qr, orderId } = await buyVipService.createOrder({
        action: userService.userData?.versionData.version === 'free' ? 'buy' : 'renew',
        chargeChannel: this.currentTabKey,
        planId: selectPlan.id,
        version: 'pro',
        source: this.props.source!,
      })
      if (this.sessionId !== sessionId) {
        return
      }
      this.qrUrl = qr

      this.pollingOrderStatus(orderId, true)
    } catch (error) {
      console.log(error)
      this.qrErr = true
    }
  }

  private async pollingOrderStatus(orderId: string, force = false, cnt = 0) {
    if (!this.props.open) {
      return
    }
    if (force) {
      try {
        const { status } = await buyVipService.pollOrderStatus(orderId)
        if (status === 'completed') {
          try {
            commonUtils.sendExtensionMessage('markPaid', {})
            userService.updateUserInfo()
          } catch (error) { }
          if (this.props.paySuccess) {
            this.props.paySuccess()
          }
          setTimeout(() => {
            if (this.props.onClose) {
              this.props.onClose()
            }
          })
          /** 停止轮询,给出成功提示 */
          this.clearTimeout()
          return
        }
        if (status === 'canceled' || status === 'refunded') {
          /** 停止轮询 */
          return
        }
        if (status === 'created') {
          /** 取消上一次轮询 */
          this.clearTimeout()
          this.timer = setTimeout(() => {
            this.pollingOrderStatus(orderId, true, 0)
          }, 1000)
        }
      } catch (error) {
        if (cnt > 5) {
          this.clearTimeout()
          this.qrErr = true
          /** 重试结束，停止轮询 */
          return
        }
        this.pollingOrderStatus(orderId, true, cnt + 1)
      }
    }
  }

  private clearTimeout() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
