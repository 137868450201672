import { commonUtils } from '@/helpers/commonUtils'
import { VIP_GIFT_LIST } from '@/pages/PricingPage/pricingConsts'
import { userService } from '@/services/userService'
import cls from 'classnames'
import { observer } from 'mobx-react'
import { CSSProperties } from 'react'
import { WelfareCountDownBox } from '../WelfareCountDownBox'
import style from './NewUserDiscount.module.less'

export interface Plan extends ServerDataTypes.VipPlan {
  priceStr: string
  originalPrice: string
  perPrice: string
  id: string
  offCount: number
}

type NewUserDiscountProps = {
  discountEndTime: number
  discount: Plan
  styleCSS?: CSSProperties
  onSkipClick: () => void
  onJoinClick: () => void
}

export const NewUserDiscount = observer((props: NewUserDiscountProps) => {
  const { discount, discountEndTime, styleCSS, onSkipClick, onJoinClick } = props

  const getUserRecordStr = (str: string) => {
    return `${str}_B`
  }

  const discountVersion = discount.version

  return (
    <section className={cls(style.discountBoxPlanB, 'col-1', 'flex-row')} style={{ ...styleCSS }}>
      <p className={style.title}>
        Hi~欢迎注册「会译」，我们为你准备了「{discountVersion === 'pro' ? 'PRO版' : '无限版'}
        」新人专属福利
        <img
          className={style.titleBg}
          src='https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_bg__title.b93b86f063f1.png'
        />
        <img
          className={cls(style.discount)}
          src={commonUtils.getStaticFile('login/tag-discount5.png')}
        />
      </p>

      <div className={cls('flex-row', 'align-side', 'justify-center', style.center)}>
        <div className={style.left}>
          <p className={cls(style.title, 'flex-row', 'align-center', 'justify-center')}>
            <img
              className={style.icon}
              src='https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_icon__free.f74d9d35dae0.png'
            />
            <span>体验版</span>
          </p>
          <div className={cls(style.freeCountArea)}>
            <img
              className={cls(style.icon)}
              src={commonUtils.getStaticFile('login/icon__free-text.png')}
            />
            <div className={cls(style.line)}>
              <div className={cls(style.item)}>
                <p className={cls(style.count)}>5000</p>
                <span className={cls(style.desc)}>Token数</span>
              </div>
              <div className={cls(style.item)}>
                <p className={cls(style.count)}>1篇</p>
                <span className={cls(style.desc)}>PDF文件数</span>
              </div>
            </div>

            <div className={cls(style.line)} style={{ marginTop: '16px' }}>
              <div className={cls(style.item)}>
                <p className={cls(style.count)}>5次</p>
                <span className={cls(style.desc)}>Chat次数</span>
              </div>
              <div className={cls(style.item)}>
                <p className={cls(style.count)}>5次</p>
                <span className={cls(style.desc)}>图片翻译次数</span>
              </div>
            </div>
          </div>

          <div className={cls(style.freeWrap)}>
            <div className={cls(style.translate)}>
              <img
                className={cls(style.tag)}
                src={commonUtils.getStaticFile('login/icon__translate.png')}
              />

              <img
                className={cls(style.tagRight)}
                src={commonUtils.getStaticFile('login/free_right-tag.png')}
              />

              <div className={cls(style.line)}>
                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__free-web.png')}
                  />
                  <p className={cls(style.text)}>网页翻译</p>
                </div>

                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__free-select.png')}
                  />
                  <p className={cls(style.text)}>划词翻译</p>
                </div>
              </div>

              <div className={cls(style.line)}>
                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__free-pdf.png')}
                  />
                  <p className={cls(style.text)}>PDF翻译</p>
                </div>

                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__free-realtime.png')}
                  />
                  <p className={cls(style.text)}>即时翻译</p>
                </div>
              </div>

              <div className={cls(style.line)}>
                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__free-video.png')}
                  />
                  <p className={cls(style.text)}>视频翻译</p>
                </div>

                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__free-image.png')}
                  />
                  <p className={cls(style.text)}>图片翻译</p>
                </div>
              </div>
            </div>

            <div className={cls(style.summary)}>
              <img
                className={cls(style.tag)}
                src={commonUtils.getStaticFile('login/icon__summary.png')}
              />

              <div className={cls(style.line)}>
                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/word-highlight-free.png')}
                  />
                  <p className={cls(style.text)}>生词高亮</p>
                </div>

                <div className={cls(style.item)} style={{ marginLeft: '57px' }}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/collect-free.png')}
                  />
                  <p className={cls(style.text)}>内容收藏</p>
                </div>
              </div>

              <div className={cls(style.line)}>
                <div className={cls(style.item)}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__summary-video.png')}
                  />
                  <p className={cls(style.text)}>网页/视频总结</p>
                </div>

                <div className={cls(style.item)} style={{ marginLeft: '27px' }}>
                  <img
                    className={cls(style.icon)}
                    src={commonUtils.getStaticFile('login/icon__summary-focus.png')}
                  />
                  <p className={cls(style.text)}>信息聚焦</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cls(style.right, { [style.proPlus]: discountVersion === 'pro+' })}>
          <img
            className={style.rightBg}
            // src='https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_bg__right.483527297bde.png'
            src={
              discountVersion === 'pro'
                ? 'https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_bg__right.483527297bde.png'
                : commonUtils.getStaticFile('login/unlimited_bg.png')
            }
          />

          {discountVersion === 'pro+' && (
            <div className={cls(style.name)}>
              <img
                className={cls(style.icon)}
                src={commonUtils.getStaticFile('login/vip_unlimited.png')}
              />
              <p className={cls(style.versionName)}>无限版</p>
            </div>
          )}

          <div
            className={cls(style.rightContent, 'flex-row', 'align-side', {
              [style.proPlus]: discountVersion === 'pro+',
            })}
          >
            <div className={style.left}>
              <div
                className={cls(style.proCountArea, { [style.proPlus]: discountVersion === 'pro+' })}
              >
                <img
                  className={cls(style.icon)}
                  src={commonUtils.getStaticFile(
                    discountVersion === 'pro' ? 'login/tag-pro.png' : 'login/tag-pro-plus.png'
                  )}
                />
                <div className={cls(style.line)}>
                  <div className={cls(style.item, { [style.proPlus]: discountVersion === 'pro+' })}>
                    <p className={cls(style.count)}>
                      {discountVersion === 'pro' ? '1,000,000/月' : '无限'}
                    </p>
                    <span className={cls(style.desc)}>Token数</span>
                  </div>
                  <div className={cls(style.item, { [style.proPlus]: discountVersion === 'pro+' })}>
                    <p className={cls(style.count)}>
                      {discountVersion === 'pro' ? '50篇/月' : '无限'}
                    </p>
                    <span className={cls(style.desc)}>PDF文件数</span>
                  </div>
                </div>

                <div className={cls(style.line)} style={{ marginTop: '16px' }}>
                  <div className={cls(style.item, { [style.proPlus]: discountVersion === 'pro+' })}>
                    <p className={cls(style.count)}>
                      {discountVersion === 'pro' ? '1000次/月' : '无限'}
                    </p>
                    <span className={cls(style.desc)}>Chat次数</span>
                  </div>
                  <div className={cls(style.item, { [style.proPlus]: discountVersion === 'pro+' })}>
                    <p className={cls(style.count)}>
                      {discountVersion === 'pro' ? '100次/月' : '无限'}
                    </p>
                    <span className={cls(style.desc)}>图片翻译次数</span>
                  </div>
                </div>
              </div>

              <div className={cls(style.wrap, { [style.proPlus]: discountVersion === 'pro+' })}>
                <div className={cls(style.translate)}>
                  <img
                    className={cls(style.tag)}
                    src={commonUtils.getStaticFile(
                      discountVersion === 'pro' ? 'login/tag-ai.png' : 'login/tag-ai-pro-plus.png'
                    )}
                  />
                  <img
                    className={cls(style.tagRight)}
                    src={commonUtils.getStaticFile(
                      discountVersion === 'pro'
                        ? 'login/pro-right-tag.png'
                        : 'login/pro-plus-right-tag.png'
                    )}
                  />

                  <div className={cls(style.line)}>
                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-web.png')}
                      />
                      <p className={cls(style.text)}>网页翻译</p>
                    </div>

                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-select.png')}
                      />
                      <p className={cls(style.text)}>划词翻译</p>
                    </div>
                  </div>

                  <div className={cls(style.line)}>
                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-pdf.png')}
                      />
                      <p className={cls(style.text)}>PDF翻译</p>
                    </div>

                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-realtime.png')}
                      />
                      <p className={cls(style.text)}>即时翻译</p>
                    </div>
                  </div>

                  <div className={cls(style.line)}>
                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-video.png')}
                      />
                      <p className={cls(style.text)}>视频翻译</p>
                    </div>

                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-image.png')}
                      />
                      <p className={cls(style.text)}>图片翻译</p>
                    </div>
                  </div>
                </div>

                <div
                  className={cls(style.summary, { [style.proPlus]: discountVersion === 'pro+' })}
                >
                  <img
                    className={cls(style.tag)}
                    src={commonUtils.getStaticFile(
                      discountVersion === 'pro' ? 'login/tag-read.png' : 'login/tag-read-plus.png'
                    )}
                  />

                  <div className={cls(style.line)}>
                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/word-highlight.png')}
                      />
                      <p className={cls(style.text)}>生词高亮</p>
                    </div>

                    <div className={cls(style.item)} style={{ marginLeft: '54px' }}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/collect-vip.png')}
                      />
                      <p className={cls(style.text)}>内容收藏</p>
                    </div>
                  </div>

                  <div className={cls(style.line)}>
                    <div className={cls(style.item)}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-summary-video.png')}
                      />
                      <p className={cls(style.text)}>网页/视频总结</p>
                    </div>

                    <div className={cls(style.item)} style={{ marginLeft: '24px' }}>
                      <img
                        className={cls(style.icon)}
                        src={commonUtils.getStaticFile('login/pro-summary-focus.png')}
                      />
                      <p className={cls(style.text)}>信息聚焦</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cls(style.right, { [style.proPlus]: discountVersion === 'pro+' })}>
              <div className={style.giftBox}>
                <div className={cls(style.title)}>
                  <p
                    className={cls(style.text, { [style.proPlusText]: discountVersion === 'pro+' })}
                  >
                    年费会员还可领取价值千元的礼包
                  </p>
                  <img
                    className={style.bgGiftTitle}
                    src={
                      discountVersion === 'pro'
                        ? 'https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_bg__right-title.571aeb1c6e85.png'
                        : commonUtils.getStaticFile('login/gift_underline_plus.png')
                    }
                  />
                </div>
                <div className={style.giftList}>
                  {VIP_GIFT_LIST.map((item, index) => {
                    return (
                      <div key={index} className={cls(style.giftItem, style.flexCol)}>
                        <img src={discount.version === 'pro' ? item.planBIcon : item.proPlusIcon} />
                        <span className={style.giftName}>{item.name}</span>
                        <span className={style.giftDesc}>{item.desc}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={cls(style.bottom, 'flex-row', 'align-center')}>
        <div className={style.priceBox}>
          <div className={cls('flex-row', 'justify-end')}>
            <span className={style.price}>{discount?.priceStr}</span>
            <span className={style.unit}>元/年</span>
            <img
              className={style.saleIcon}
              src='https://assets.weibanzhushou.com/web/pt-web/login-page_giftPlanB_sale-5.0ee00b25aca4.png'
            />
          </div>
          <p className={style.originalPrice}>原价{discount?.originalPrice}元/年</p>
        </div>
        <div className={style.btnBox}>
          <WelfareCountDownBox
            className={style.countdownBox}
            end={discountEndTime!}
            timeEnd={() => {}}
          ></WelfareCountDownBox>
          <button
            className={cls(style.payBtn, { [style.proPlus]: discountVersion === 'pro+' })}
            onClick={() => {
              userService.trackUserRecord(getUserRecordStr('click_new_user_discount_pay_w'))
              onJoinClick()
            }}
          >
            立即加入
            <img
              src='https://assets.weibanzhushou.com/web/pt-web/login-page_icon__arrow-right.e1252da981ac.png'
              className={style.iconArrowRight}
            />
          </button>
        </div>
        <span
          className={style.jump}
          onClick={() => {
            userService.trackUserRecord(getUserRecordStr('click_new_user_discount_jump_w'))
            onSkipClick()
          }}
        >
          跳过
        </span>
      </div>
    </section>
  )
})
