import { commonUtils } from '@/helpers/commonUtils'
import { reactionHelper } from '@/helpers/globalHelpers'
import { PDFDocumentProxy } from '@/helpers/pdfTranslateHelper/pdf'
import { pdfTranslateHelper } from '@/helpers/pdfTranslateHelper/pdfTranslateHelper'
import { PdfTranslateHelperTypes } from '@/helpers/pdfTranslateHelper/types'
import { BaseVm } from '@/mobxTools'
import { translateService, TranslateServiceType } from '@/services/translateService'
import { userService } from '@/services/userService'
import { DocumentLoadEvent } from '@react-pdf-viewer/core'
import { throttle } from 'lodash'
import { observable, reaction } from 'mobx'
import type { PdfViewerProps } from './PdfViewer'
import { uiController } from '@/controllers/uiController'

export const MAX_PDF_PAGE_WITHOUT_PAY = 3

export class PdfViewerVm extends BaseVm<PdfViewerProps> {
  /** props 不应该被修饰为 state */
  props!: PdfViewerProps
  public pdfViewRoot: HTMLDivElement | null = null
  // 翻译结果
  @observable translateResult: PdfTranslateHelperTypes.PageData[] = []
  @observable currentTranslateFromLang = ''
  @observable currentTranslateToLang = ''
  @observable pdfLoadingProgress = 0
  // states
  @observable htmlLoaded = false
  @observable translatePageData: PdfTranslateHelperTypes.PageData[] = []
  @observable pdfDocumentLoaded = false
  @observable allLocales: TranslateServiceType.LocaleList = []
  // 实际的缩放比例
  @observable scaleFactor = 1
  @observable currentPage = 0
  @observable docSizeList: Array<{ w: number; h: number }> = []

  private pdfRenderContainerEl: HTMLDivElement | null = null
  public translateContainerEl: HTMLElement | null = null
  public translateHtmlRenderEl: HTMLElement | null = null

  private pdfDoc: PDFDocumentProxy | null = null

  private translateDebounceCall = throttle(
    (params: {
      doc: PDFDocumentProxy
      currentPage: number
      scaleFactor: number
      from: string
      to: string
    }) => {
      const pdfRenderType = this.props.pdfRenderType
      if (!this.translateContainerEl || !this.translateHtmlRenderEl || !this.pdfRenderContainerEl) {
        return
      }

      let enableTranslate = true
      // 没有购买过的情况下，非分享链接打开的情况下只翻译前 3 页
      if (
        !this.props.shareHashId &&
        !userService.hasPaid &&
        this.docSizeList.length > MAX_PDF_PAGE_WITHOUT_PAY
      ) {
        if (params.currentPage >= MAX_PDF_PAGE_WITHOUT_PAY - 2) {
          enableTranslate = false
        }

        if (params.currentPage >= MAX_PDF_PAGE_WITHOUT_PAY) {
          //超出3页提示
          console.log('超出第3页了，', params.currentPage, enableTranslate)
          uiController.pdfLimitModalProps = {
            open: true,
            limitType: 'translate',
          }
        }
      }

      const onlyParseOriginal =
        pdfRenderType === 'initial' || pdfRenderType === 'original' || !enableTranslate
      this.doTranslate({
        ...params,
        shareHashId: this.props.shareHashId,
        fileId: this.props.fileId,
        onlyParseOriginal,
      })
    },
    100,
    { leading: true }
  )

  public onPropsChange(props: PdfViewerProps, oldProps?: PdfViewerProps | undefined): void {
    if (props?.fileUrl && props?.fileUrl !== oldProps?.fileUrl) {
      this.resetTranslateStatus()
    }
  }

  public onViewCreate(): void {
    translateService.getLocaleList().then((data) => {
      this.allLocales = data
    })
    reactionHelper.getProxy(this).add(
      reaction(
        () => [userService.pdfSourceLang, userService.pdfTargetLang],
        () => {
          this.resetTranslateResult()
          this.translateDebounceCall({
            currentPage: this.currentPage,
            doc: this.pdfDoc!,
            from: userService.pdfSourceLang,
            scaleFactor: this.scaleFactor,
            to: userService.pdfTargetLang,
          })
        }
      )
    )
    this.handlePdfRenderContainerScroll = this.handlePdfRenderContainerScroll.bind(this)
    this.handleTranslateContainerElScroll = this.handleTranslateContainerElScroll.bind(this)
  }

  public onViewDestroy(): void {
    reactionHelper.getProxy(this).disposeAll()
    this.removeScrollEventListener()
  }

  public handlePdfDocumentLoaded(e: DocumentLoadEvent) {
    // this.resetTranslateStatus()
    const doc = e.doc as unknown as PDFDocumentProxy
    this.pdfDoc = doc
    this.pdfDocumentLoaded = true
    this.updateDocSizeList(doc)
    this.setPdfRenderContainerEl()
    // this.translateDebounceCall({
    //   currentPage: this.currentPage,
    //   doc: this.pdfDoc,
    //   from: userService.pdfSourceLang,
    //   scaleFactor: this.scaleFactor,
    //   to: userService.pdfTargetLang,
    // })
    this.translate()
    this.addScrollEventListener()
  }

  public translate() {
    this.translateDebounceCall({
      currentPage: this.currentPage,
      doc: this.pdfDoc!,
      from: userService.pdfSourceLang,
      scaleFactor: this.scaleFactor,
      to: userService.pdfTargetLang,
    })
  }

  public async handlePdfPageChange(currentPage: number) {
    this.currentPage = currentPage
    this.translate()
    // this.translateDebounceCall({
    //   currentPage: this.currentPage,
    //   doc: this.pdfDoc!,
    //   from: userService.pdfSourceLang,
    //   scaleFactor: this.scaleFactor,
    //   to: userService.pdfTargetLang,
    // })
  }

  public handlePdfRenderTypeChange() {
    this.translateDebounceCall({
      currentPage: this.currentPage,
      doc: this.pdfDoc!,
      from: userService.pdfSourceLang,
      scaleFactor: this.scaleFactor,
      to: userService.pdfTargetLang,
    })
  }

  public handlePdf2HtmlRendered(el: HTMLElement, renderEl: HTMLElement) {
    // console.log('htmlLoaded')
    this.htmlLoaded = true
    this.translateContainerEl = el
    this.translateHtmlRenderEl = renderEl
    this.addScrollEventListener()
    this.translateDebounceCall({
      currentPage: this.currentPage,
      doc: this.pdfDoc!,
      from: userService.pdfSourceLang,
      scaleFactor: this.scaleFactor,
      to: userService.pdfTargetLang,
    })
  }

  private async updateDocSizeList(doc: PDFDocumentProxy) {
    const docSize: Array<{ w: number; h: number }> = []
    for (let i = 0; i < doc.numPages; i++) {
      const page = await doc.getPage(i + 1)
      const viewport = page.getViewport({ scale: 1 })
      docSize.push({ w: viewport.width, h: viewport.height })
    }
    // console.warn('set doc size', docSize)
    this.docSizeList = docSize
  }

  private setPdfRenderContainerEl() {
    this.pdfRenderContainerEl = document.querySelector('.rpv-core__inner-pages') as HTMLDivElement
  }

  private async doTranslate(params: {
    fileId: number
    doc: PDFDocumentProxy
    currentPage: number
    scaleFactor: number
    from: string
    to: string
    /** 仅解析渲染原文，不翻译 */
    onlyParseOriginal: boolean
    shareHashId?: string
  }) {
    await pdfTranslateHelper.doTranslate(
      { ...params, containerEl: this.translateHtmlRenderEl! },
      (data) => {
        this.translatePageData = data
        if (this.translateContainerEl && this.pdfRenderContainerEl) {
          this.translateContainerEl!.scrollTop = this.pdfRenderContainerEl!.scrollTop
        }
      }
    )
  }

  public async addScrollEventListener(retry = 0) {
    if (retry > 10) {
      return
    }
    await commonUtils.delay(200)
    const pdfRenderContainerEl = this.pdfRenderContainerEl
    const translateContainerEl = this.translateContainerEl
    if (!pdfRenderContainerEl || !translateContainerEl) {
      this.addScrollEventListener(retry + 1)
      return
    }
    this.removeScrollEventListener()

    pdfRenderContainerEl.addEventListener('scroll', this.handlePdfRenderContainerScroll)
    translateContainerEl.addEventListener('scroll', this.handleTranslateContainerElScroll)
  }

  private removeScrollEventListener() {
    const pdfRenderContainerEl = this.pdfRenderContainerEl
    const translateContainerEl = this.translateContainerEl

    pdfRenderContainerEl?.removeEventListener('scroll', this.handlePdfRenderContainerScroll)
    translateContainerEl?.removeEventListener('scroll', this.handleTranslateContainerElScroll)
  }

  private handlePdfRenderContainerScroll() {
    this.translateContainerEl!.scrollTop = this.pdfRenderContainerEl!.scrollTop
  }

  private handleTranslateContainerElScroll() {
    this.pdfRenderContainerEl!.scrollTop = this.translateContainerEl!.scrollTop
  }

  private resetTranslateStatus() {
    this.removeScrollEventListener()
    this.translateContainerEl = null
    this.translateHtmlRenderEl = null
    this.pdfRenderContainerEl = null
    this.pdfDocumentLoaded = false
    this.htmlLoaded = false
    this.translatePageData = []
    pdfTranslateHelper.resetStatus('all')
  }
  private resetTranslateResult() {
    this.translatePageData.forEach(({ paragraphs }) => {
      paragraphs.forEach((p) => {
        p.translateResult = ''
      })
    })
    pdfTranslateHelper.resetStatus('result')
  }
}
