import { uiController } from '@/controllers/uiController'
import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import { ComponentFactory } from '@/mobxTools'
import { userService } from '@/services/userService'
import { Modal, QRCode, Spin } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { Tooltip } from '../Tooltip'
import { VipExpiredTipsPanel } from '../VipExpiredTipsPanel'
import { WelfareCountDownBox } from '../WelfareCountDownBox'
import style from './BuyVipDialogV1.module.less'
import { BuyVipDialogV1Vm, Plan } from './BuyVipDialogV1Vm'

/** BuyVipDialogV1 组件的 Props */
export type BuyVipDialogV1Props = {
  open: boolean
  selectedPlanId?: string
  newUser?: 1
  source?: string
  version?: 'pro' | 'pro+'
  paySuccess?: (enableReceiveGift: boolean) => void
  onClose?: (isPaySuccessful: boolean) => void
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const BuyVipDialogV1 = ComponentFactory.createComponent(
  'BuyVipDialogV1',
  BuyVipDialogV1Vm,
  (vm) => {
    if (!vm.selectPlan) {
      return null
    }
    const { open, onClose } = vm.props
    const { benefits } = vm
    const { wxAvatar, nickname, inviteCode, versionData } = userService.userData!
    let endTime = versionData.endTime
    if (userService.vipVersion === 'pro+') {
      endTime = versionData.vipTimeData.proPlusEndTime
    } else if (versionData.vipTimeData) {
      const { proPlusEndTime, proEndTime } = versionData.vipTimeData
      if (proPlusEndTime) {
        endTime = !proEndTime || proPlusEndTime > proEndTime ? proPlusEndTime : proEndTime
      }
    }
    const isProPlus = vm.curVipType === 'pro+'

    return (
      <Modal
        zIndex={Z_INDEX_MAX - 100}
        maskClosable={false}
        open={open}
        centered
        wrapClassName={classNames(style.buyVipDialog)}
        footer={false}
        width='969px'
        getContainer={false}
        onCancel={() => {
          if (onClose) {
            onClose(false)
          }
        }}
      >
        <div className={classNames(style.payDialogContent)}>
          {/* 左侧面板 */}
          <div className={classNames(style.left)}>
            {/* 用户信息到期时间 */}
            <div className={classNames(style.userInfo)}>
              {wxAvatar ? <img src={wxAvatar} /> : <img src={commonUtils.getDefaultAvatar()} />}

              <div>
                <div className={classNames(style.userName)}>
                  {nickname ? nickname : `会译${inviteCode.slice(0, 4).toUpperCase()}`}
                </div>
                {versionData.version !== 'free' && (
                  <div className={classNames(style.userExpired)}>
                    到期时间：{dayjsHelper.formatTime(endTime * 1000)}
                    <VipExpiredTipsPanel from='dialog'></VipExpiredTipsPanel>
                  </div>
                )}
              </div>
            </div>
            <div className={classNames(style.title)}>
              <span>千元礼包免费领</span>
            </div>
            <p className={classNames(style.vipDesc)}>
              开通会译年费会员即可免费领取
              <br />
              每个账号仅可领取一次哦～
            </p>
            <div className={classNames(style.line)}></div>
            {/* 会员礼包列表 */}
            <div className={classNames(style.vipGiftList)}>
              {vm.vipGiftList.map((item) => {
                return (
                  <div key={item.name} className={classNames(style.giftItem)}>
                    <img className={classNames(style.icon)} src={item.planBIcon} />
                    <span className={classNames(style.giftName)}>{item.name}</span>
                    <Tooltip title={item.desc}>
                      <span className={classNames(style.giftDesc)}>{item.desc}</span>
                    </Tooltip>
                  </div>
                )
              })}
            </div>
          </div>

          <div className={classNames(style.right)}>
            <div className={classNames(style.tabs, style.flexRow)}>
              <div
                className={classNames(style.tab, style.flexRow, {
                  [style.active]: vm.curVipType === 'pro',
                })}
                onClick={() => {
                  vm.handleChangeVipType('pro')
                }}
              >
                <img
                  src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__pro.b8f7a2ece42b.png'
                  className={classNames(style.tabIcon)}
                />
                <span>Pro会员</span>
                <img
                  src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_path__tab-active.5b7acb8a4e47.png'
                  alt=''
                  className={classNames(style.pathActive)}
                />
              </div>
              <div
                className={classNames(style.tab, style.flexRow, {
                  [style.active]: vm.curVipType === 'pro+',
                })}
                onClick={() => {
                  vm.handleChangeVipType('pro+')
                }}
              >
                <img
                  src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__vip-tab.967250af50bd.png'
                  className={classNames(style.tabIcon)}
                />
                <span>无限会员</span>
                <img
                  src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_path__tab-active.5b7acb8a4e47.png'
                  alt=''
                  className={classNames(style.pathActive)}
                />
              </div>
            </div>
            <div
              className={classNames(style.scrollBox, { [style.proVBox]: vm.curVipType === 'pro+' })}
            >
              <div className={classNames(style.benefitsBox)}>
                <div className={classNames(style.benefitItemBox)}>
                  {isProPlus && (
                    <img
                      className={classNames(style.tag)}
                      src={commonUtils.getStaticFile('buy_vip_unlimited_tag.png')}
                    />
                  )}
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-token.aeaa44970d32.png' />
                      <span>Token数</span>
                    </div>
                    {!isProPlus && (
                      <div className={classNames(style.label)}>{benefits.tokenCount}</div>
                    )}
                  </div>
                  {isProPlus && (
                    <div className={classNames(style.benefitItem)}>
                      <div className={classNames(style.benefitDetail)}>
                        <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-pdf-size.1dba3310781d.png' />
                        <span>网页翻译</span>
                      </div>
                    </div>
                  )}

                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-pdf-upload.af7fe7c71a4d.png' />
                      <span>PDF文件数</span>
                    </div>
                    {!isProPlus && (
                      <div className={classNames(style.label)}>{benefits.pdfUploadCount}篇/月</div>
                    )}
                  </div>
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-chat.382ada4377f3.png' />
                      <span>Chat对话</span>
                    </div>
                    {!isProPlus && (
                      <div className={classNames(style.label)}>{benefits.pdfChatCount}次/月</div>
                    )}
                  </div>
                  {!isProPlus && (
                    <div className={classNames(style.benefitItem)}>
                      <div className={classNames(style.benefitDetail)}>
                        <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-pdf-size.1dba3310781d.png' />
                        <span>文件大小</span>
                      </div>
                      {!isProPlus && (
                        <div className={classNames(style.label)}>{benefits.pdfMaxPage}页/篇</div>
                      )}
                    </div>
                  )}
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-image.fd9fe2941b58.png' />
                      <span>图片翻译</span>
                    </div>
                    {!isProPlus && (
                      <div className={classNames(style.label)}>{benefits.imageCount}张/月</div>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className={classNames(style.benefitsBox)}>
                <div className={classNames(style.benefitItemBox)}>
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-token.aeaa44970d32.png' />
                      <span>Token数</span>
                    </div>
                    <div className={classNames(style.label)}>{benefits.tokenCount}</div>
                  </div>
                </div>
                <div className={classNames(style.benefitItemBox)}>
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-pdf-upload.af7fe7c71a4d.png' />
                      <span>PDF文件数</span>
                    </div>
                    <div className={classNames(style.label)}>{benefits.pdfUploadCount}篇/月</div>
                  </div>
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-chat.382ada4377f3.png' />
                      <span>Chat对话</span>
                    </div>
                    <div className={classNames(style.label)}>{benefits.pdfChatCount}次/月</div>
                  </div>
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-pdf-size.1dba3310781d.png' />
                      <span>文件大小</span>
                    </div>
                    <div className={classNames(style.label)}>{benefits.pdfMaxPage}页/篇</div>
                  </div>
                </div>
                <div className={classNames(style.benefitItemBox)}>
                  <div className={classNames(style.benefitItem)}>
                    <div className={classNames(style.benefitDetail)}>
                      <img src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__benefit-image.fd9fe2941b58.png' />
                      <span>图片翻译</span>
                    </div>
                    <div className={classNames(style.label)}>{benefits.imageCount}张/月</div>
                  </div>
                </div>
              </div> */}

              <div className={classNames(style.funcList, { [style.pb]: vm.hasDiscountPlan })}>
                {vm.funcList.map((func) => {
                  return (
                    <div
                      key={func.key}
                      className={classNames(style.funcItem, {
                        [style.active]: vm.curFuncKey === func.key,
                      })}
                      onMouseEnter={() => {
                        vm.curFuncKey = func.key
                      }}
                    >
                      {vm.curVipType === 'pro' ? (
                        <img
                          className={classNames(style.active)}
                          src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_icon__func-active.1bd24d7678e9.png'
                        />
                      ) : (
                        <img
                          className={classNames(style.active)}
                          src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_new_icon__func-active-pro-v.58cfd14e6fc4.png'
                        />
                      )}

                      <img className={classNames(style.icon)} src={func.icon1} />
                      <div className={classNames(style.desc)}>
                        <p className={classNames(style.label)}>{func.label}</p>
                        <p className={classNames(style.intro)}>{func.intro}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={classNames(style.bottom)}>
              {vm.vipPlans.map((plan) => {
                return <PlanItem key={plan.id} vm={vm} plan={plan}></PlanItem>
              })}
              <div className={classNames(style.paymentBox)}>
                <div className={classNames(style.qrBox)}>
                  <img
                    src='https://assets.weibanzhushou.com/web/pt-web/login-page_bg__qr.518234b9d754.png'
                    className={classNames(style.qrBg)}
                  />
                  <Spin spinning={vm.orderCreating} size='small'>
                    <QRCode
                      status={vm.orderCreating ? 'loading' : 'active'}
                      value={vm.orderQrCode}
                      size={120}
                      bordered={false}
                      style={{ padding: 0, borderRadius: '0px' }}
                    ></QRCode>
                  </Spin>
                  {vm.qrErr && (
                    <div
                      className={classNames(style.reloadBox)}
                      onClick={async () => {
                        if (vm.alipayAgree && vm.selectPlan.isSubscription) {
                          uiController.commonModalProps = {
                            open: true,
                            title: '“连续包季”购买提醒',
                            content: `您当前${
                              versionData.agreeType.indexOf('pro+') > -1 ? '无限' : 'Pro'
                            }会员已使用连续包季的模式，需解约后重新扫码签约，原套餐解约后不再自动扣费。`,
                            okText: '帮我解约原套餐',
                            cancelText: '我再想想',
                            onOk: async () => {
                              uiController.commonModalProps = { open: false }
                              await vm.alipayUnsign()
                              vm.alipayAgree = false
                              vm.genOrderQrCode()
                            },
                            onCancel: () => {
                              uiController.commonModalProps = { open: false }
                            },
                          }
                          return
                        }
                        vm.genOrderQrCode()
                      }}
                    >
                      <img src='https://assets.weibanzhushou.com/web/pt-web/icon__qr-reload.b68e5cd3c901.png' />
                      <span>刷新</span>
                    </div>
                  )}
                </div>
                <div>
                  <div className={classNames(style.channelList)}>
                    <div
                      className={classNames(style.alipayBox, style.flexRow, {
                        [style.active]: vm.currentTabKey === 'alipay_qr',
                      })}
                      onClick={() => {
                        vm.currentTabKey = 'alipay_qr'
                        vm.genOrderQrCode()
                      }}
                    >
                      <img src='https://assets.weibanzhushou.com/web/pt-web/ali-pay.18b4779d8424.png' />
                      <span>支付宝支付</span>
                    </div>
                    <div
                      className={classNames(style.wechatBox, style.flexRow, {
                        [style.active]: vm.currentTabKey === 'wx_pub_qr',
                        [style.disable]: vm.selectPlan.isSubscription,
                      })}
                      onClick={() => {
                        if (vm.selectPlan.isSubscription) {
                          return
                        }
                        vm.currentTabKey = 'wx_pub_qr'
                        vm.genOrderQrCode()
                      }}
                    >
                      <img src='https://assets.weibanzhushou.com/web/pt-web/wechat-pay.9115a8ba339f.png' />
                      <span>微信支付</span>
                      {vm.selectPlan.isSubscription && (
                        <div className={classNames(style.tips)}>
                          {vm.selectPlan.title}服务暂未开通微信渠道
                        </div>
                      )}
                    </div>
                  </div>
                  <p className={classNames(style.vipTitleBold)}>VIP会员-{vm.selectPlan.title}</p>
                  <p className={classNames(style.vipTotal)}>￥{vm.selectPlan.priceStr}</p>
                  {vm.selectPlan.isSubscription && (
                    <p className={classNames(style.subscriptionPdf)}>
                      开通即表示同意
                      <a
                        href='https://cdn.weibanzhushou.com/weiban-prod/%E4%BC%9A%E8%AF%91%E8%87%AA%E5%8A%A8%E7%BB%AD%E8%B4%B9%E6%9C%8D%E5%8A%A1%E8%A7%84%E5%88%99.pdf'
                        target='_blank'
                        rel='noreferrer'
                      >
                        《会译自动续费协议》
                      </a>
                    </p>
                  )}
                </div>
              </div>
              {vm.selectPlan.isSubscription && (
                <p className={classNames(style.subscriptionTips)}>
                  {vm.selectPlan.priceDesc}，可随时在“设置-自动续费管理”中关闭
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  }
)

const PlanItem = observer(({ vm, plan }: { vm: BuyVipDialogV1Vm; plan: Plan }) => {
  const getSubscriptionIcon = () => {
    switch (plan.timeType) {
      case 'year_subscription':
        return 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_shounianyouhui.eccd85bf4451.png'
      case 'quarter_subscription':
        return 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_shoujiyouhui.4a733813be8b.png'
      default:
        return 'https://assets.weibanzhushou.com/web/pt-web/pay-dialog_shouyueyouhui.bdd6bba6562a.png'
    }
  }
  return (
    <li
      key={plan.id}
      className={classNames(style.selectionBox, {
        [style.active]: plan.id === vm.selectPlan.id,
      })}
      onClick={() => {
        vm.selectPlan = plan
        vm.handlePriceChange()
      }}
    >
      <img
        src='https://assets.weibanzhushou.com/web/pt-web/bg__plan-active.fee40fedd6f7.png'
        alt=''
        className={classNames(style.planActiveBg)}
      />
      <img
        src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__word-count-active.e4b46358d388.png'
        className={classNames(style.iconPlanActive)}
      />
      <div>
        <div className={classNames(style.selectionBoxFirst)}>
          <span>{plan.title}</span>
        </div>
        <div className={classNames(style.selectionBoxMiddle)}>
          <span className={classNames(style.pricePrefix)}>￥</span>
          <span className={classNames(style.priceText)}>{plan.priceStr}</span>
        </div>
        {plan.originPrice !== plan.price && !plan.isSubscription && (
          <span className={classNames(style.originalPrice)}>原价{plan.originalPriceStr}元</span>
        )}
      </div>
      {plan.isSubscription && !vm.discountEndTime && (
        <span className={classNames(style.tips)}>
          <img className={classNames(style.subscriptionIcon)} src={getSubscriptionIcon()} />
        </span>
      )}
      {plan.newUserDiscount && (
        <span className={classNames(style.discountTips)}>
          <img
            className={classNames(style.discountIcon)}
            src='https://assets.weibanzhushou.com/web/pt-web/pay-dialog_icon__xianshiyouhui.c6377e257356.png'
          />
        </span>
      )}
      {plan.newUserDiscount && (
        <WelfareCountDownBox
          end={vm.discountEndTime}
          prefixText='福利倒计时'
          className={style.payDialogCountdown}
          timeEnd={() => {
            // 倒计时结束了
          }}
        ></WelfareCountDownBox>
      )}
    </li>
  )
})
