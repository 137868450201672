import { LanguagePopover } from '@/components/LanguagePopover/LanguagePopover'
import LoginModal from '@/components/LoginModal/LoginModal'
import { OnlineFooter } from '@/components/OnlineFooter/OnlineFooter'
import { toastController } from '@/controllers/toastController'
import { api } from '@/helpers/api'
import { PAGE_LINKS, TranslateServiceConsts } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import { userService } from '@/services/userService'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { debounce } from 'lodash'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { OnlineHeader } from './OnlineHeader/OnlineHeader'
import style from './OnlineTranslation.module.less'
import { TranslateServiceType, translateService } from '@/services/translateService'

export const OnlineTranslation = observer(() => {
  const [showLoginModal, setShowLoginModal] = useState(false)
  const onlinePageRef = useRef<HTMLDivElement | null>(null)
  const goInstall = (utm: string = 'guid_flow') => {
    window.open(`${envHelper.webHost}${PAGE_LINKS.install}?utm=${utm}`)
  }

  return (
    <div className={classNames(style.onlineTranslation)} ref={onlinePageRef}>
      <OnlineHeader
        onLogin={() => {
          setShowLoginModal(true)
        }}
        onLogout={() => userService.logout()}
        onCurrentTabClick={() => {
          onlinePageRef.current!.scrollTop = 0
        }}
      />
      {/* 翻译区域 */}
      <div className={classNames(style.translationBox)}>
        <OnlineTranslationBox></OnlineTranslationBox>
        <div className={classNames(style.right)}>
          <p className={classNames(style.title)}>「会译」不止于在线翻译</p>

          <div className={classNames(style.card)}>
            <div className={classNames(style.header)}>
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('online__pdf.png')}
              />
              <p className={classNames(style.text)}>会译·PDF阅读器</p>

              <div
                className={classNames(style.btn)}
                onClick={() => {
                  window.open(`${envHelper.webHost}${PAGE_LINKS.pdf}?utm=guid_PDF`)
                }}
              >
                <p className={classNames(style.text)}>立即体验</p>
                <img
                  className={classNames(style.arrow)}
                  src={commonUtils.getStaticFile('online_arrow-right.png')}
                />
              </div>
            </div>

            <p className={classNames(style.desc)}>
              ✨对照式翻译+AI问答，快速、准确地将PDF翻译成目标语言，围绕PDF内容自由提问深入理解PDF内容。
            </p>
          </div>

          <div className={classNames(style.cardBottom)}>
            <div className={classNames(style.header)} style={{ padding: '0 9px' }}>
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('online__logo.png')}
              />
              <p className={classNames(style.text)}>会译·对照式翻译（插件）</p>

              <div className={classNames(style.btn)} onClick={() => goInstall('guid_translate')}>
                <p className={classNames(style.text)}>立即安装</p>
                <img
                  className={classNames(style.arrow)}
                  src={commonUtils.getStaticFile('online_arrow-right.png')}
                />
              </div>
            </div>

            <div className={classNames(style.detail)}>
              <div className={classNames(style.title)}>
                <img
                  className={classNames(style.icon)}
                  src={commonUtils.getStaticFile('online__hands.png')}
                />
                <p className={classNames(style.desc)}>解决浏览器网页中的所有翻译场景：</p>
              </div>
              <div className={classNames(style.list)}>
                <div className={classNames(style.item)}>
                  <img
                    className={classNames(style.icon)}
                    src={commonUtils.getStaticFile('online__hook.png')}
                  />
                  <p className={classNames(style.text)}>
                    <span className={classNames(style.pre)}>网页翻译：</span>
                    全文对照，双语、原/译文随时切换阅读模式
                  </p>
                </div>
                <div className={classNames(style.item)}>
                  <img
                    className={classNames(style.icon)}
                    src={commonUtils.getStaticFile('online__hook.png')}
                  />
                  <p className={classNames(style.text)}>
                    <span className={classNames(style.pre)}>划词翻译：</span>
                    一键选词，快捷翻译，满足多场景翻译需求
                  </p>
                </div>
                <div className={classNames(style.item)}>
                  <img
                    className={classNames(style.icon)}
                    src={commonUtils.getStaticFile('online__hook.png')}
                  />
                  <p className={classNames(style.text)}>
                    <span className={classNames(style.pre)}>即时翻译：</span>
                    填写词/句，即时翻译浏览器外文本
                  </p>
                </div>
                <div className={classNames(style.item)}>
                  <img
                    className={classNames(style.icon)}
                    src={commonUtils.getStaticFile('online__hook.png')}
                  />
                  <p className={classNames(style.text)}>
                    <span className={classNames(style.pre)}>图片翻译：</span>
                    翻译后的图片自动取代网页原始图片
                  </p>
                </div>
                <div className={classNames(style.item)}>
                  <img
                    className={classNames(style.icon)}
                    src={commonUtils.getStaticFile('online__hook.png')}
                  />
                  <p className={classNames(style.text)}>
                    <span className={classNames(style.pre)}>视频翻译：</span>
                    双语字幕，提升观看体验，快速获取信息
                  </p>
                </div>
              </div>
              <p className={classNames(style.last)}>还有更多AI阅读功能等你体验～～～</p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(style.descriptionBox)}>
        <div className={classNames(style.title)}>
          <p className={classNames(style.text)}>会译 不止于在线翻译</p>
          <img
            className={classNames(style.line)}
            src={commonUtils.getStaticFile('online__line.png')}
          />
        </div>
        {/* 网页翻译 */}
        <div className={classNames(style.contentCard)}>
          <img
            className={classNames(style.img)}
            src={commonUtils.getStaticFile('online__web2.png')}
          />

          <div className={classNames(style.desc)} style={{ marginRight: '88px' }}>
            <div className={classNames(style.iconWrap)}>
              <img
                className={classNames(style.icon)}
                src={
                  'https://assets.weibanzhushou.com/web/pt-web/home-page_web-translate.4b3f25505595.svg'
                }
              />
            </div>
            <div className={classNames(style.info)} style={{ marginTop: '24px' }}>
              <div className={classNames(style.tag)}>
                沉浸式翻译服务，双语对照阅读
                <div className={classNames(style.line)}></div>
              </div>
              <p className={classNames(style.text)}>
                「会译」提供了一种双语网页阅读体验，能够精确地翻译网页内容，同事保持网页的原始外观不变。这样，你就能阅读母语一样自然、沉浸地浏览网页，极大地提高了获取信息的效率
              </p>
            </div>

            <div className={classNames(style.info)} style={{ marginTop: '56px' }}>
              <div className={classNames(style.tag)}>
                告别机器翻译，全新强大的AI翻译能力
                <div className={classNames(style.line)}></div>
              </div>
              <p className={classNames(style.text)}>
                「会译」插件利用高级LLM模型，专为翻译而设计，提供精确、专业且地道的翻译，比DeepL、百度翻译、又到翻译等更优。它是学术、信息搜集和工作科研的必备翻译工具
              </p>
            </div>

            <div className={classNames(style.install)} onClick={() => goInstall()}>
              免费体验
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('btn__arrow.png')}
              />
            </div>
          </div>
        </div>

        {/* pdf */}
        <div className={classNames(style.contentCard)}>
          <div className={classNames(style.desc)} style={{ marginLeft: '88px' }}>
            <div className={classNames(style.iconWrap)}>
              <img
                className={classNames(style.icon)}
                src={
                  'https://assets.weibanzhushou.com/web/pt-web/home-page_pdf-translate.2992418582ca.svg'
                }
              />
            </div>
            <div className={classNames(style.info)} style={{ marginTop: '24px' }}>
              <div className={classNames(style.tag)}>
                PDF双语对照翻译
                <div className={classNames(style.line)}></div>
              </div>
              <p className={classNames(style.text)}>
                「会译」支持上传PDF文档进行双语对照翻译阅读。您只需上传PDF文件，就能得到对应语言版本的PDF文档。无论是科研论文、工作文件还是学术研究文档，「会译」都能提供精准的翻译结果。
              </p>
            </div>

            <div className={classNames(style.info)} style={{ marginTop: '56px' }}>
              <div className={classNames(style.tag)}>
                独创chatPDF功能，与PDF进行交流
                <div className={classNames(style.line)}></div>
              </div>
              <p className={classNames(style.text)}>
                上传PDF之后，您可以像与人交流一样，对任意PDF内容提出问题。这极大地提高了信息获取的效率。通过问答的方式，您能迅速掌握文档中所有的关键要点。
              </p>
            </div>

            <div className={classNames(style.install)} onClick={() => goInstall()}>
              免费体验
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('btn__arrow.png')}
              />
            </div>
          </div>

          <img
            className={classNames(style.img)}
            src={commonUtils.getStaticFile('online__pdf_big2.png')}
          />
        </div>

        {/* 划词翻译 */}
        <div className={classNames(style.contentCard)}>
          <img
            className={classNames(style.img)}
            src={commonUtils.getStaticFile('online__select.png')}
          />

          <div className={classNames(style.desc)} style={{ marginRight: '88px' }}>
            <div className={classNames(style.iconWrap)}>
              <img
                className={classNames(style.icon)}
                src={
                  'https://assets.weibanzhushou.com/web/pt-web/home-page_huaci-translate.bf31b6e764d7.svg'
                }
              />
            </div>
            <div className={classNames(style.info)} style={{ marginTop: '24px' }}>
              <div className={classNames(style.tag)}>
                划词翻译，AI词典
                <div className={classNames(style.line)}></div>
              </div>
              <p className={classNames(style.text)}>
                会译内置AI词典，可以随时随地进行查词翻译。在您阅读网页内容时，遇到晦涩单词，鼠标轻轻一划即可得到翻译结果，沉浸式翻译任意内容，高效率处理翻译难题。
              </p>
            </div>

            <div className={classNames(style.info)} style={{ marginTop: '56px' }}>
              <div className={classNames(style.tag)}>
                首创聚焦focus功能
                <div className={classNames(style.line)}></div>
              </div>
              <p className={classNames(style.text)}>
                在网页浏览中，如果遇到不懂的术语，专业名词，段落，只需轻轻一点，即可得到由AI给出的答案，所有难题迎刃而解！宛如得到一位得力的人工智能助手。
              </p>
            </div>

            <div className={classNames(style.install)} onClick={() => goInstall()}>
              免费体验
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('btn__arrow.png')}
              />
            </div>
          </div>
        </div>

        {/* 视频 */}
        <div className={classNames(style.contentCard)}>
          <div className={classNames(style.desc)} style={{ marginLeft: '88px' }}>
            <div className={classNames(style.iconWrap)}>
              <img
                className={classNames(style.icon)}
                src={
                  'https://assets.weibanzhushou.com/web/pt-web/home-page_tab-icon__video.b89b9ffbcaf4.png'
                }
              />
            </div>
            <div className={classNames(style.info)} style={{ marginTop: '24px' }}>
              <div className={classNames(style.tag)}>
                视频字幕翻译
                <div className={classNames(style.line)}></div>
              </div>
              <p className={classNames(style.text)}>
                「会译」现在支持几乎所有国外主流视频网站的双语实时字幕翻译功能。这项功能打破了信息获取的最后一道障碍。有了双语字幕翻译，观看视频就像在IMAX影院里看「中英双语」字幕电影一样，让您能轻松、无障碍地理解视频内容。
              </p>
            </div>

            <div className={classNames(style.install)} onClick={() => goInstall()}>
              免费体验
              <img
                className={classNames(style.icon)}
                src={commonUtils.getStaticFile('btn__arrow.png')}
              />
            </div>
          </div>

          <img
            className={classNames(style.img)}
            src={commonUtils.getStaticFile('online__video2.png')}
          />
        </div>
      </div>

      {/* footer */}
      <OnlineFooter
        onCurrentTabClick={() => {
          onlinePageRef.current!.scrollTop = 0
        }}
        showLinks={true}
      />

      {/* 登录弹窗 */}
      {showLoginModal && (
        <LoginModal
          open={showLoginModal}
          onCancelClick={() => {
            setShowLoginModal(false)
          }}
          onLoginSuccess={() => {
            setShowLoginModal(false)
          }}
        />
      )}
    </div>
  )
})

export const OnlineTranslationBox = observer(
  ({ cls, disabledAutoFocus }: { cls?: string; disabledAutoFocus?: boolean }) => {
    const [sourceText, setSourceText] = useState('')
    const [resultText, setResultText] = useState('')
    const [loading, setLoading] = useState(false)
    const abortRef = useRef<AbortController | undefined>(undefined)

    const debounceSearch = useCallback(
      debounce(async (sourceText: string) => {
        if (sourceText.trim().length === 0) {
          setResultText('')
          return
        }

        if (loading) {
          abortRef.current?.abort()
        }

        const detectLang = commonUtils.detectTextLang(sourceText)
        let targetLang = userService.onlineTranslationTargetLang
        if (detectLang === 'zh-Hans' && detectLang === userService.onlineTranslationTargetLang) {
          targetLang = 'en'
          userService.onlineTranslationTargetLang = targetLang
        }
        if (detectLang === 'en' && detectLang === targetLang) {
          targetLang = 'zh-Hans'
          userService.onlineTranslationTargetLang = targetLang
        }

        setLoading(true)
        const controller = new AbortController()
        abortRef.current = controller
        try {
          const { respContentList } = await api.OnlineTranslation.translate(
            {
              fromLang: userService.onlineTranslationSourceLang,
              toLang: targetLang,
              textList: [sourceText],
              textType: 'plain',
              token: commonUtils.genVisitorTokenStr(),
            },
            controller
          )

          const resultText = respContentList.join('') || sourceText
          setResultText(resultText)
          setLoading(false)
        } catch (error) {
          if (error.name === 'AbortError') {
          } else {
            setLoading(false)
            toastController.error(commonUtils.formatErrorMsg(error))
          }
        }
      }, 600),
      [loading]
    )

    const switchLanguage = () => {
      const sourceLang = userService.onlineTranslationSourceLang

      userService.onlineTranslationSourceLang = userService.onlineTranslationTargetLang
      userService.onlineTranslationTargetLang = sourceLang

      debounceSearch(sourceText)
    }

    return (
      <div className={classNames(style.left, style.translationRoot, cls)}>
        <div className={classNames(style.language)}>
          <div className={classNames(style.languageItem)}>
            <img
              className={classNames(style.icon)}
              src={commonUtils.getStaticFile('online__source-lang.png')}
            />
            <p className={classNames(style.label)}>输入语言：</p>

            <LanguagePopover
              placeholder={'请输入搜索内容'}
              emptyPlaceholder={'不支持当前语言'}
              languageList={userService.sourceLanguageOptions}
              value={userService.onlineTranslationSourceLang}
              onLanguageChange={(value) => {
                userService.onlineTranslationSourceLang = value
                debounceSearch(sourceText)
              }}
              onLoadChildren={(languageItem) => {
                return (
                  <div className={classNames(style.selectLang)}>
                    <p className={classNames(style.text)} title={languageItem.label}>
                      {languageItem.label}
                    </p>
                    <img
                      className={classNames(style.arrow)}
                      src={commonUtils.getStaticFile('online__arrow.png')}
                    />
                  </div>
                )
              }}
            ></LanguagePopover>
          </div>

          <img
            className={classNames(style.switch)}
            src={commonUtils.getStaticFile('online__switch.png')}
            onClick={() => {
              switchLanguage()
            }}
          />

          <div className={classNames(style.languageItem)}>
            <img
              className={classNames(style.icon)}
              src={commonUtils.getStaticFile('online__source-lang.png')}
            />
            <p className={classNames(style.label)}>目标语言：</p>
            <LanguagePopover
              placeholder={'请输入搜索内容'}
              emptyPlaceholder={'不支持当前语言'}
              languageList={userService.targetLanguageOptions}
              value={userService.onlineTranslationTargetLang}
              onLanguageChange={(value) => {
                userService.onlineTranslationTargetLang = value
                debounceSearch(sourceText)
              }}
              onLoadChildren={(languageItem) => {
                return (
                  <div className={classNames(style.selectLang)}>
                    <p className={classNames(style.text)} title={languageItem.label}>
                      {languageItem.label}
                    </p>
                    <img
                      className={classNames(style.arrow)}
                      src={commonUtils.getStaticFile('online__arrow.png')}
                    />
                  </div>
                )
              }}
            ></LanguagePopover>
          </div>
        </div>

        <div className={classNames(style.inputArea)}>
          <div className={classNames(style.source)}>
            <textarea
              placeholder='输入需要翻译的单词或句子，即可直接翻译'
              value={sourceText}
              autoFocus={!disabledAutoFocus}
              onChange={(e) => {
                const val = e.target.value
                setSourceText(val)
                debounceSearch(val)
              }}
            ></textarea>
            {sourceText && (
              <Tooltip title='清空'>
                <img
                  className={classNames(style.clear)}
                  src={commonUtils.getStaticFile('online_clear.png')}
                  onClick={() => {
                    setSourceText('')
                    setResultText('')
                  }}
                />
              </Tooltip>
            )}
          </div>

          <div className={classNames(style.target)}>
            <div className={classNames(style.result)}>
              {loading ? (
                <span>
                  <span className={classNames(style.dot)}>.</span>
                  <span className={classNames(style.dot, style.dot2)}>.</span>
                  <span className={classNames(style.dot, style.dot3)}>.</span>
                </span>
              ) : (
                resultText
              )}
            </div>

            {!loading && resultText && (
              <Tooltip title='复制'>
                <div
                  className={classNames(style.copy)}
                  onClick={() => {
                    commonUtils.copyToClipboard(resultText)
                    toastController.success('复制成功')
                  }}
                >
                  <img
                    className={classNames(style.icon)}
                    src={commonUtils.getStaticFile('online_copy.png')}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    )
  }
)
