import { theme, ThemeConfig } from 'antd'
import type { AliasToken } from 'antd/es/theme/internal'
import { commonUtils } from './commonUtils'
import { envHelper } from './envHelper'

const { getDesignToken } = theme
const defaultThemeTokenConfig: Partial<AliasToken> = {
  colorPrimary: '#832fff',
  colorPrimaryHover: '#772AE9',
  colorText: '#333333',
}
const lenovoThemeTokenConfig: Partial<AliasToken> = {
  colorPrimary: '#6165F7',
  colorPrimaryHover: '#595DE3',
  colorText: '#333333',
}

class ThemeHelper {

  init() {
    const { colorPrimary, colorPrimaryHover, colorText } = this.themeToken
    const rootEl = document.querySelector(':root') as HTMLElement
    rootEl.style.setProperty('--color-primary', colorPrimary!)
    rootEl.style.setProperty('--color-primary-hover', colorPrimaryHover!)
    rootEl.style.setProperty('--color-text', colorText!)
    if (envHelper.oem === 'lenovo') {
      document.querySelector('link[rel="shortcut icon"]')?.setAttribute('href', commonUtils.getStaticFile('cdn/logo-transparent.ico'))
    }
  }

  get theme(): ThemeConfig {
    return {
      cssVar: true,
      token: this.themeToken,
    }
  }

  get themeToken(): Partial<AliasToken> {
    const oem = envHelper.oem
    let tokenConfig = defaultThemeTokenConfig
    if (oem === 'lenovo') {
      tokenConfig = lenovoThemeTokenConfig
    }
    return getDesignToken({ token: tokenConfig })
  }
}

export const themeHelper = new ThemeHelper()