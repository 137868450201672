class EnvHelper {
  private _oem: OEM_TYPE = 'default'
  get extensionPrefix() {
    return 'huiyi_webapp'
  }

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get productName() {
    return '会译'
  }

  get host() {
    return process.env.REACT_APP_HOST
  }

  get webHost() {
    return process.env.REACT_APP_WEB_HOST
  }

  get apiHost() {
    return this.webHost
  }

  get apiBasePath() {
    return '/hy_api'
  }

  get oem(): OEM_TYPE {
    return this._oem
  }
}

export const envHelper = new EnvHelper()
