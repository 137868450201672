import { get } from './common'
import { educationIncentives } from './educationIncentives'
import { invite } from './invite'
import { learning } from './learning'
import { login } from './login'
import { onlineTranslate } from './onlineTranslate'
import { OnlineTranslation } from './onlineTranslation'
import { pdf } from './pdf'
import { pricing } from './pricing'
import { shareWxMp } from './shareWxMp'
import { user } from './user'

export function getHuiyiUrlConfig() {
  return get<ServerDataTypes.UrlConfig>('/url_config')
}
export const api = {
  login,
  pricing,
  invite,
  user,
  pdf,
  shareWxMp,
  educationIncentives,
  getHuiyiUrlConfig,
  OnlineTranslation,
  learning,
  onlineTranslate,
}
