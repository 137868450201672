import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { ComponentFactory } from '@/mobxTools'
import { Modal, QRCode, Spin } from 'antd'
import classNames from 'classnames'
import style from './PayQrDialog.module.less'
import { PayQrDialogVm } from './PayQrDialogVm'

/** PayQrDialog 组件的 Props */
export type PayQrDialogProps = {
  open: boolean
  plan: ServerDataTypes.VipPlan
  source?: string
  paySuccess?: () => void
  onClose: () => void
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const PayQrDialog = ComponentFactory.createComponent('PayQrDialog', PayQrDialogVm, (vm) => {
  const { open, plan, onClose } = vm.props
  return (
    <Modal
      width='572px'
      zIndex={Z_INDEX_MAX}
      getContainer={false}
      maskClosable={false}
      wrapClassName={classNames(style.payQrDialog)}
      open={open}
      centered
      footer={false}
      onCancel={() => {
        onClose()
      }}
    >
      <div className={classNames(style.payQrDialogContent)}>
        <p className={classNames(style.title)}>扫码支付</p>
        <div className={classNames(style.channelList)}>
          <div
            className={classNames(style.alipayBox, style.flexRow, {
              [style.active]: vm.currentTabKey === 'alipay_qr',
            })}
            onClick={() => {
              if (vm.currentTabKey === 'alipay_qr') {
                return
              }
              vm.currentTabKey = 'alipay_qr'
              vm.genOrderQrCode()
            }}
          >
            <img src='https://assets.weibanzhushou.com/web/pt-web/ali-pay.18b4779d8424.png' />
            <span>支付宝支付</span>
          </div>
          <div
            className={classNames(style.wechatBox, style.flexRow, {
              [style.active]: vm.currentTabKey === 'wx_pub_qr',
            })}
            onClick={() => {
              if (vm.currentTabKey === 'wx_pub_qr') {
                return
              }
              vm.currentTabKey = 'wx_pub_qr'
              vm.genOrderQrCode()
            }}
          >
            <img src='https://assets.weibanzhushou.com/web/pt-web/wechat-pay.9115a8ba339f.png' />
            <span>微信支付</span>
          </div>
        </div>

        <div className={classNames(style.qrBox)}>
          <img
            src='https://assets.weibanzhushou.com/web/pt-web/login-page_bg__qr.518234b9d754.png'
            className={classNames(style.qrBg)}
          />

          {vm.qrErr && (
            <div
              className={classNames(style.reloadBox)}
              onClick={() => {
                vm.genOrderQrCode()
              }}
            >
              <img
                className={classNames(style.icon)}
                src='https://assets.weibanzhushou.com/web/pt-web/icon__qr-reload.b68e5cd3c901.png'
              />
              <span>刷新</span>
            </div>
          )}
          {!vm.qrErr && (
            <Spin spinning={!vm.qrUrl} size='small'>
              {vm.qrUrl && (
                <QRCode
                  value={vm.qrUrl}
                  size={220}
                  bordered={false}
                  style={{ padding: 0 }}
                ></QRCode>
              )}
            </Spin>
          )}
        </div>
        <div className={classNames(style.priceBox)}>
          <span className={classNames(style.discountPrice)}>
            ¥{commonUtils.parsePriceStr(plan.price)}
          </span>
          <span className={classNames(style.originalPrice)}>
            原价{commonUtils.parsePriceStr(plan.originPrice)}元/年
          </span>
        </div>
      </div>
    </Modal>
  )
})
