import { PAGE_LINKS } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import { userService } from '@/services/userService'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import Marquee from 'react-fast-marquee'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import style from './OnlineHeader.module.less'

type OnlineHeaderProps = {
  onLogout: () => void
  onLogin: () => void
  onCurrentTabClick: () => void
}

type TabName = 'onlineTranslation' | 'pdf' | 'install' | 'guide' | 'price'

export const OnlineHeader = observer((props: OnlineHeaderProps) => {
  const { onLogout, onLogin, onCurrentTabClick } = props

  const location = useLocation()
  const { width } = useWindowSize()

  const handleTabClick = (tab: TabName) => {
    if (tab === 'onlineTranslation') {
      if (location.pathname === PAGE_LINKS.onlineTranslation) {
        onCurrentTabClick()
        return
      }
      window.open(`${envHelper.webHost}${PAGE_LINKS.onlineTranslation}`)
    } else if (tab === 'pdf') {
      if (location.pathname === PAGE_LINKS.pdf) {
        onCurrentTabClick()
        return
      }
      window.open(`${envHelper.webHost}${PAGE_LINKS.pdf}?utm=guid_PDF`)
    } else if (tab === 'install') {
      if (location.pathname === PAGE_LINKS.install) {
        onCurrentTabClick()
        return
      }
      window.open(`${envHelper.webHost}${PAGE_LINKS.install}?utm=guid_left_top`)
    } else if (tab === 'guide') {
      window.open('https://huiyiai.net/blog/')
    } else if (tab === 'price') {
      if (location.pathname === PAGE_LINKS.pricing) {
        onCurrentTabClick()
        return
      }
      window.open(`${envHelper.webHost}${PAGE_LINKS.pricing}`)
    }
  }

  const maxWidth = userService.isLogin ? 1300 : 1450

  return (
    <div className={classNames(style.onlineHeader)}>
      <a href='/' target='_blank'>
        <img
          className={classNames(style.logo)}
          src={commonUtils.getStaticFile('online-header-logo.png')}
        />
      </a>
      <div className={classNames(style.tabWrap)}>
        <div
          className={classNames(style.tab, {
            [style.active]: location.pathname === PAGE_LINKS.onlineTranslation,
          })}
          onClick={() => handleTabClick('onlineTranslation')}
        >
          在线翻译
          <div className={classNames(style.underline)}></div>
        </div>

        <div className={classNames(style.tab)} onClick={() => handleTabClick('pdf')}>
          PDF翻译
          {/* <div className={classNames(style.underline)}></div> */}
        </div>

        <div className={classNames(style.tab)} onClick={() => handleTabClick('install')}>
          网页翻译插件
          <TextMarquee marquee={Math.floor(width) <= maxWidth} />
        </div>
      </div>

      <div className={classNames(style.right)}>
        <div className={classNames(style.link)} onClick={() => handleTabClick('guide')}>
          翻译指南
        </div>
        <div
          className={classNames(style.link)}
          style={{ marginLeft: '40px' }}
          onClick={() => handleTabClick('price')}
        >
          定价
        </div>

        {!userService.isLogin && (
          <div
            className={style.link}
            style={{ marginLeft: '40px' }}
            onClick={() => {
              onLogin()
            }}
          >
            登录
          </div>
        )}
        {!userService.isLogin && (
          <div
            className={classNames(style.link, style.register)}
            style={{ marginLeft: '32px' }}
            onClick={() => {
              onLogin()
            }}
          >
            免费注册
          </div>
        )}
        {userService.isLogin && (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'logout',
                  label: '退出登录',
                  onClick: () => {
                    onLogout()
                  },
                },
              ],
            }}
            placement='bottom'
          >
            <div className={style.avatar}>
              <img
                className={style.userAvatar}
                src={userService.userData!.wxAvatar || commonUtils.getDefaultAvatar()}
              />
              <img className={style.rect} src={commonUtils.getStaticFile('pdf/rect.png')} />
            </div>
          </Dropdown>
        )}
      </div>
    </div>
  )
})

const TextMarquee = ({ marquee }: { marquee: boolean }) => {
  if (!marquee) {
    return (
      <div className={classNames(style.extension)}>
        <img
          className={classNames(style.icon)}
          src={commonUtils.getStaticFile('pdf/icon__puzzle-fragment.png')}
        />
        <p className={classNames(style.text)}>
          安装「会译小插件」体验更多翻译能力：网页翻译、划词翻译、视频翻译、图片翻译
        </p>
      </div>
    )
  }

  return (
    <div className={classNames(style.extension, { [style.marquee]: marquee })}>
      <Marquee pauseOnHover={true}>
        <p className={classNames(style.text)} style={{ marginRight: '24px' }}>
          <img
            className={classNames(style.icon)}
            src={commonUtils.getStaticFile('pdf/icon__puzzle-fragment.png')}
          />
          安装「会译小插件」体验更多翻译能力：网页翻译、划词翻译、视频翻译、图片翻译
        </p>
      </Marquee>
    </div>
  )
}
