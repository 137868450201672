/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * NOTE:
 * @dependencyLevel 0
 * 这个模块不能引用其他任何模块 (type 方式或第三方依赖除外)
 */

import { IReactionDisposer } from 'mobx'

interface TimerStore {
  intervals: { [i: number]: number }
  timeouts: { [i: number]: number }
}

class TimerHelper {
  idCounter = 1

  setInterval(context: any, handler: () => void, time: number) {
    const intervalStore = this.getStore(context).intervals
    const ret = window.setInterval(handler, time)
    intervalStore[this.idCounter] = ret
    this.idCounter += 1
    return this.idCounter - 1
  }

  clearInterval(context: any, intervalId: number) {
    const intervalStore = this.getStore(context).intervals
    window.clearInterval(intervalStore[intervalId])
    delete intervalStore[intervalId]
  }

  setTimeout(context: any, handler: () => void, time: number) {
    const timeoutStore = this.getStore(context).timeouts
    const ret = window.setTimeout(() => {
      handler()
      delete timeoutStore[ret]
    }, time)
    timeoutStore[this.idCounter] = ret
    this.idCounter += 1
    return this.idCounter - 1
  }

  clearTimeout(context: any, intervalId: number) {
    const timeoutStore = this.getStore(context).timeouts
    window.clearTimeout(timeoutStore[intervalId])
    delete timeoutStore[intervalId]
  }

  clearAll(context: any) {
    const store = this.getStore(context)
    for (const key of Object.keys(store.intervals)) {
      window.clearInterval(store.intervals[key as any])
    }
    for (const key of Object.keys(store.timeouts)) {
      window.clearTimeout(store.timeouts[key as any])
    }
  }

  private getStore(context: any): TimerStore {
    if (context.__timerHelperStore) {
      return context.__timerHelperStore
    }
    const store: TimerStore = { intervals: {}, timeouts: {} }
    context.__timerHelperStore = store
    return store
  }
}

type AnyFunc = (...args: any[]) => any

interface Listenable {
  addEventListener: AnyFunc
  removeEventListener: AnyFunc
}

interface ListenerProxy<T extends Listenable> {
  addEventListener: T['addEventListener']
  removeAllListeners: () => void
}

interface ListenerHelperStore {
  listeners: {
    [id: string]: Array<{ event: string; handler: AnyFunc; extra: any[] }>
  }
  targets: { [id: string]: any }
}

class ListenerHelper {
  static idCounter = 0
  getProxy<T extends Listenable>(
    context: any,
    target: T | null,
    targetLabel?: string
  ): ListenerProxy<T> {
    if (!target && !targetLabel) {
      throw new Error('must specify target or targetLabel')
    }
    const store = this.getStore(context)
    const id = targetLabel || this.getTargetId(store, target)
    if (target && !store.targets[id]) {
      store.targets[id] = target
      store.listeners[id] = []
    }
    return {
      addEventListener(event: any, handler: any, ...extra: any[]) {
        store.listeners[id].push({ event, handler, extra })
        store.targets[id].addEventListener(event, handler, ...extra)
      },
      removeAllListeners() {
        store.listeners[id].forEach(({ event, handler, extra }) => {
          store.targets[id].removeEventListener(event, handler, ...extra)
        })
        delete store.targets[id]
        delete store.listeners[id]
      },
    }
  }

  private getTargetId(store: ListenerHelperStore, target: any): string {
    for (const id of Object.keys(store.targets)) {
      if (store.targets[id] === target) {
        return id
      }
    }
    return `_${++ListenerHelper.idCounter}`
  }

  private getStore(context: any): ListenerHelperStore {
    const key = '__listenerHelperStore'
    if (context[key]) {
      return context[key]
    }
    const store: ListenerHelperStore = { listeners: {}, targets: {} }
    context[key] = store
    return store
  }
}

interface ReactionHelperStore {
  disposers: IReactionDisposer[]
}

class ReactionHelper {
  getProxy(context: any) {
    const store = this.getStore(context)
    const proxy = {
      /** can add disposer of reaction or autorun */
      add(disposer: IReactionDisposer) {
        store.disposers.push(disposer)
        return proxy
      },
      disposeAll() {
        store.disposers.forEach((disposer) => disposer())
        store.disposers = []
      },
    }
    return proxy
  }
  private getStore(context: any): ReactionHelperStore {
    const key = '__reactionHelperStore'
    if (context[key]) {
      return context[key]
    }
    const store: ReactionHelperStore = { disposers: [] }
    context[key] = store
    return store
  }
}

export const timerHelper = new TimerHelper()
export const listenerHelper = new ListenerHelper()
export const reactionHelper = new ReactionHelper()
